import { ctpTokens, HHBox, HHDivider, HHFormControlLabel, HHGrid, HHPopover, HHRadio, HHRadioGroup, HHTableCell, HHTableHead, HHTableRow, HHTableSortLabel, HHTypography } from '@hinge-health/react-component-library';
import FilterListIcon from '@mui/icons-material/FilterList';
import { makeStyles } from '@mui/styles';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SPLIT_TREATMENTS as PT_SPLIT_TREATEMENTS } from '../../../../acute-physical-therapy/constants/splits';
import { MEMBER_ALERT_BANNER_GRID_STYLES } from '../../../../member-360/modules/member-alerts/constants/styles';
import { ON } from '../../../constants/splits';
import { LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME } from '../../../constants/strings/coach-dashboard-default';
import { OS_NO_RESPONSE_PREDICTED_TAB_NAME } from '../../../constants/strings/os-layout';
import { NO_RESPONSE_PREDICTED_TAB_NAME } from '../../../constants/strings/pt-layout';
import { COACH_WORKFLOWS_TABLE, PT_WORKFLOWS_TABLE } from '../../../constants/strings/workflows-table';
import { useShellContext } from '../../../contexts/shell.context';
import { DashboardType, UnreadFilterType } from '../../../custom-types';
import { changePT, listToMarkWorkflowComplete, selectedCoachDashboardFilters, selectedDashboardType, selectedOsDashboardFilters, selectedPtDashboardFilters, selectedUnreadType, sortWorkflowsAtom, totalAllTypesCount, totalAllTypesCountLowPriority, totalModularIamCount, totalModularIamCountLowPriority, totalUnreadCount, totalUnreadCountLowPriority } from '../../../jotai/atoms';
import { coachCallFilters, CoachDashboardFilters } from '../../../types/coach';
import { houseCallFilters, ptCarePlanFilters, PtDashboardFilters, videoVisitFilters } from '../../../types/pt';
import { getCompleteWorkflowFilters } from '../../../utils/get-complete-workflow-filters';
import { WorkflowBulkMark } from './table-row-cells/workflow-bulk-mark';
import { TableHeader } from './workflows-table-container';
const useStyles = makeStyles({
  paper: {
    marginLeft: '-2.1vw'
  }
});
const styles = {
  cell: {
    borderBottom: 'none'
  },
  divider: {
    display: 'table-row'
  },
  filterButton: {
    cursor: 'pointer',
    backgroundColor: 'none',
    marginLeft: '0.5vw',
    fontSize: '1.2rem'
  },
  radioGroupPadding: {
    paddingX: 4,
    paddingY: 2
  }
};
type Order = 'ASC' | 'DESC';
interface WorkflowsHeaderCellProps {
  title: string | JSX.Element;
  selectedFilter: CoachDashboardFilters;
  enableSort?: boolean;
  active?: boolean;
  direction?: Order;
  showFilterIcon?: boolean;
  onClick?: () => void;
}
interface WorkflowsTableHeaderProps {
  tableHeaders: TableHeader[];
}
interface TitleProps {
  title: string | JSX.Element;
}
const Title: React.FC<TitleProps> = ({
  title
}) => <HHTypography hhVariant="variant-bypass" variant="body2" color={ctpTokens.palette.neutral[40]}>
    {title}
  </HHTypography>;
export const FILTER_OPTIONS = [UnreadFilterType.AllTypes, UnreadFilterType.Messages, UnreadFilterType.HealthLog];
type OptionType = UnreadFilterType[];
const unreadFilterLabelMap: Record<UnreadFilterType, string> = {
  [UnreadFilterType.AllTypes]: 'All Types',
  [UnreadFilterType.Messages]: 'Messages',
  [UnreadFilterType.HealthLog]: 'HL Message'
};
export const prepareRadioLabels = (options: OptionType, totalCount: number, messageCountTotal: number, iamMessageCountTotal: number): string[] => options.map((option: UnreadFilterType) => {
  const label = unreadFilterLabelMap[option];
  switch (option) {
    case UnreadFilterType.AllTypes:
      return `${label} (${totalCount})`;
    case UnreadFilterType.Messages:
      return `${label} (${messageCountTotal})`;
    case UnreadFilterType.HealthLog:
      return `${label} (${iamMessageCountTotal})`;
    default:
      return label;
  }
});
const WorkflowsHeaderCell = ({
  title,
  enableSort,
  active,
  direction,
  onClick,
  showFilterIcon = false
}: WorkflowsHeaderCellProps): JSX.Element => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedUnreadFilter, setSelectedUnreadFilter] = useAtom(selectedUnreadType);
  const [selectedOption, setSelectedOption] = useState<number>(FILTER_OPTIONS.indexOf(selectedUnreadFilter || UnreadFilterType.AllTypes));
  const [filteredState, setFilteredState] = useState<boolean>(false);
  const [allTypesCountTotal] = useAtom(totalAllTypesCount);
  const [messageCountTotal] = useAtom(totalUnreadCount);
  const [iamMessageCountTotal] = useAtom(totalModularIamCount);
  const [totalUnreadLowPriority] = useAtom(totalUnreadCountLowPriority);
  const [totalAllTypesLowPriority] = useAtom(totalAllTypesCountLowPriority);
  const [totalmodularIAMLowPriority] = useAtom(totalModularIamCountLowPriority);
  const [selectedPtDashboardFilter, setSelectedDashboardFilter] = useAtom(selectedPtDashboardFilters);
  const filterButtonStyles = useMemo(() => ({
    ...styles.filterButton,
    color: filteredState ? '#00A749' : ctpTokens.palette.neutral[10]
  }), [filteredState]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (selectedUnreadFilter && selectedUnreadFilter !== UnreadFilterType.AllTypes) {
      setFilteredState(true);
    } else {
      setFilteredState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnreadFilter]);
  const handleOptionChange = (event_0: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedValue = parseInt(event_0.target.value);
    setSelectedOption(selectedValue);
    setSelectedUnreadFilter(FILTER_OPTIONS[selectedValue]);
    setSelectedDashboardFilter(prevFilter => ({
      ...prevFilter,
      filterPageNumber: 0
    }));
    handleClose();
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // Use useMemo to only re-calculate when dependencies change
  const radioLabels = useMemo(() => {
    if (selectedPtDashboardFilter.filterTabName === NO_RESPONSE_PREDICTED_TAB_NAME) {
      return prepareRadioLabels(FILTER_OPTIONS, totalAllTypesLowPriority, totalUnreadLowPriority, totalmodularIAMLowPriority);
    } else {
      return prepareRadioLabels(FILTER_OPTIONS, allTypesCountTotal, messageCountTotal, iamMessageCountTotal);
    }
  }, [selectedPtDashboardFilter.filterTabName, totalAllTypesLowPriority, totalUnreadLowPriority, totalmodularIAMLowPriority, allTypesCountTotal, messageCountTotal, iamMessageCountTotal]);
  return <HHTableCell sx={styles.cell}>
      {enableSort ? <HHTableSortLabel active={active} direction={active ? (direction?.toLowerCase() as 'asc' | 'desc') : undefined} onClick={onClick}>
          <HHTypography hhVariant="body">
            <Title title={title} />
          </HHTypography>
        </HHTableSortLabel> : <HHBox sx={{
      display: 'flex',
      alignItems: 'center'
    }}>
          <HHTypography hhVariant="body" sx={{
        marginRight: '4px'
      }}>
            <Title title={title} />
          </HHTypography>
          {showFilterIcon ? <>
              <HHGrid container sx={{
          MEMBER_ALERT_BANNER_GRID_STYLES
        }}>
                <FilterListIcon fontSize="medium" data-testid={'filter-icon'} sx={{
            ...filterButtonStyles
          }} onClick={handleClick} />
              </HHGrid>

              <HHPopover data-testid={id} id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }} transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }} classes={{
          paper: classes.paper
        }}>
                <HHRadioGroup value={selectedOption} onChange={handleOptionChange} sx={styles.radioGroupPadding} data-testid="unread-filter-radio-group">
                  {radioLabels.map((label: string, index: number) => <React.Fragment key={index}>
                      <HHFormControlLabel label={label} value={index} control={<HHRadio hhVariant="primary" />} data-testid="unread-filter-radio-label" />
                      {FILTER_OPTIONS[index] === UnreadFilterType.AllTypes && <HHDivider />}
                    </React.Fragment>)}
                </HHRadioGroup>
              </HHPopover>
            </> : null}
        </HHBox>}
    </HHTableCell>;
};
const getTitle = (header: TableHeader): string | JSX.Element => {
  if (header.label === COACH_WORKFLOWS_TABLE.HEADERS.SELECT) {
    return <WorkflowBulkMark markType={'header'} />;
  } else if (header.label === COACH_WORKFLOWS_TABLE.HEADERS.MARK) {
    return '';
  } else {
    return header.label;
  }
};
const WorkflowsTableHeader: React.FC<WorkflowsTableHeaderProps> = ({
  tableHeaders
}) => {
  const [selectedDashboardFilter] = useAtom(selectedCoachDashboardFilters);
  const [selectedPtDashboardFilter] = useAtom(selectedPtDashboardFilters);
  const [selectedOsDashboardFilter] = useAtom(selectedOsDashboardFilters);
  const [dashboardType] = useAtom(selectedDashboardType);
  const [sortWorkflows] = useAtom(sortWorkflowsAtom);
  const [listToMarkComplete] = useAtom(listToMarkWorkflowComplete);
  const [isChangePT] = useAtom(changePT);
  const splitClient = useClient();
  const {
    adminProfile
  } = useShellContext();
  const {
    TYPE,
    ENCOUNTER_DATE,
    CREATED_ON,
    COMPLETED_ON,
    DUE,
    MEMBER,
    COUNT_SELECTED,
    MARK,
    SELECT,
    MARK_COMPLETE
  } = COACH_WORKFLOWS_TABLE.HEADERS;
  const {
    CHANNEL,
    MESSAGE,
    STATE,
    PLAN_EXPIRATION
  } = PT_WORKFLOWS_TABLE.HEADERS;
  const ptPooledDashboardTreatment = splitClient?.getTreatment(PT_SPLIT_TREATEMENTS.PT_POOLED_DASHBOARD, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  const ptPooledReassignementTreatment = splitClient?.getTreatment(PT_SPLIT_TREATEMENTS.PT_POOLED_REASSIGNEMNT, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  const modularIamUnreadMessageTreatment = splitClient?.getTreatment(PT_SPLIT_TREATEMENTS.MODULAR_IAM_UNREAD_MESSAGE, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  const isCompleteWorkflowFilter = getCompleteWorkflowFilters([selectedDashboardFilter.filterLabel, selectedPtDashboardFilter.filterLabel, selectedOsDashboardFilter.filterLabel]);
  const getCoachTableHeaders = useCallback((): TableHeader[] | null => {
    if (selectedDashboardFilter.filterTabName === LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME) {
      return tableHeaders.filter(data => [TYPE, MEMBER, CREATED_ON, DUE, MARK, SELECT].includes(data.label));
    }
    if (isCompleteWorkflowFilter) {
      return tableHeaders.filter(data_0 => [TYPE, MEMBER, COMPLETED_ON].includes(data_0.label));
    }
    if (selectedDashboardFilter.filterTabName === LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME && listToMarkComplete.length === 0) {
      return tableHeaders.filter(data_1 => ![COUNT_SELECTED, MARK_COMPLETE].includes(data_1.label));
    }
    if (coachCallFilters.includes(selectedDashboardFilter.filterLabel)) {
      return tableHeaders.filter(data_2 => [TYPE, MEMBER, ENCOUNTER_DATE, DUE].includes(data_2.label));
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDashboardFilter.filterTabName, tableHeaders, isCompleteWorkflowFilter, listToMarkComplete.length, selectedDashboardFilter.filterLabel]);
  const getPtTableHeaders = useCallback((): TableHeader[] => {
    const videoVisitOrHouseCall = [...videoVisitFilters, ...houseCallFilters].includes(selectedPtDashboardFilter.filterLabel);
    const isUnreadMessageFilter = selectedPtDashboardFilter.filterLabel === PtDashboardFilters.UnreadMessage;
    if (selectedPtDashboardFilter.filterTabName === NO_RESPONSE_PREDICTED_TAB_NAME) {
      if (PtDashboardFilters.HealthLogNotes === selectedPtDashboardFilter.filterLabel) {
        return tableHeaders.filter(data_3 => [MESSAGE, MEMBER, ptPooledDashboardTreatment ? STATE : null, CREATED_ON, DUE, MARK, SELECT].includes(data_3.label));
      }
      if (isUnreadMessageFilter && modularIamUnreadMessageTreatment) {
        return tableHeaders.filter(data_4 => [CHANNEL, TYPE, MEMBER, ptPooledDashboardTreatment ? STATE : null, CREATED_ON, DUE, MARK, SELECT].includes(data_4.label));
      }
      if (isUnreadMessageFilter && !ptPooledDashboardTreatment) {
        return tableHeaders.filter(data_5 => [modularIamUnreadMessageTreatment ? CHANNEL : MESSAGE, modularIamUnreadMessageTreatment ? TYPE : null, MEMBER, CREATED_ON, DUE, MARK, SELECT].includes(data_5.label));
      }
      return tableHeaders.filter(data_6 => [MESSAGE, MEMBER, ptPooledDashboardTreatment ? STATE : null, CREATED_ON, DUE, MARK, SELECT].includes(data_6.label));
    }
    if (isCompleteWorkflowFilter) {
      return tableHeaders.filter(data_7 => [TYPE, MEMBER, ...(ptPooledDashboardTreatment ? [STATE] : []), COMPLETED_ON].includes(data_7.label));
    }
    if (isChangePT && ptPooledReassignementTreatment) {
      if (selectedPtDashboardFilter.filterLabel === PtDashboardFilters.UnreadMessage) {
        return tableHeaders.filter(data_8 => [MESSAGE, MEMBER, ptPooledDashboardTreatment ? STATE : null, CREATED_ON, DUE, MARK, SELECT].includes(data_8.label));
      }
      if (videoVisitOrHouseCall) {
        return tableHeaders.filter(data_9 => [TYPE, MEMBER, STATE, ENCOUNTER_DATE, DUE, MARK, SELECT].includes(data_9.label));
      }
      if (ptCarePlanFilters.includes(selectedPtDashboardFilter.filterLabel)) {
        return tableHeaders.filter(data_10 => [TYPE, MEMBER, STATE, PLAN_EXPIRATION, MARK, SELECT].includes(data_10.label));
      }
      return tableHeaders.filter(data_11 => [TYPE, MEMBER, STATE, CREATED_ON, DUE, MARK, SELECT].includes(data_11.label));
    } else if (ptPooledDashboardTreatment) {
      if (isUnreadMessageFilter) {
        return tableHeaders.filter(data_12 => [modularIamUnreadMessageTreatment ? CHANNEL : MESSAGE, modularIamUnreadMessageTreatment ? TYPE : null, MEMBER, ptPooledDashboardTreatment ? STATE : null, CREATED_ON, DUE].includes(data_12.label));
      }
      if (videoVisitOrHouseCall) {
        return tableHeaders.filter(data_13 => [TYPE, MEMBER, STATE, ENCOUNTER_DATE, DUE].includes(data_13.label));
      }
      if (ptCarePlanFilters.includes(selectedPtDashboardFilter.filterLabel)) {
        return tableHeaders.filter(data_14 => [TYPE, MEMBER, STATE, PLAN_EXPIRATION].includes(data_14.label));
      }
      return tableHeaders.filter(data_15 => [TYPE, MEMBER, STATE, CREATED_ON, DUE].includes(data_15.label));
    } else {
      if (isUnreadMessageFilter) {
        return tableHeaders.filter(data_16 => [modularIamUnreadMessageTreatment ? CHANNEL : MESSAGE, modularIamUnreadMessageTreatment ? TYPE : null, MEMBER, CREATED_ON, DUE].includes(data_16.label));
      }
      if (videoVisitOrHouseCall) {
        return tableHeaders.filter(data_17 => [TYPE, MEMBER, ENCOUNTER_DATE, DUE].includes(data_17.label));
      }
      if (ptCarePlanFilters.includes(selectedPtDashboardFilter.filterLabel)) {
        return tableHeaders.filter(data_18 => [TYPE, MEMBER, PLAN_EXPIRATION].includes(data_18.label));
      }
      return tableHeaders.filter(data_19 => [TYPE, MEMBER, CREATED_ON, DUE].includes(data_19.label));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPtDashboardFilter.filterTabName, tableHeaders, isCompleteWorkflowFilter, isChangePT, selectedPtDashboardFilter.filterLabel, ptPooledDashboardTreatment, ptPooledReassignementTreatment]);
  const getOsTableHeaders = useCallback((): TableHeader[] | null => {
    if (selectedOsDashboardFilter.filterTabName === OS_NO_RESPONSE_PREDICTED_TAB_NAME) {
      return tableHeaders.filter(data_20 => [TYPE, MEMBER, CREATED_ON, DUE, MARK, SELECT].includes(data_20.label));
    }
    if (isCompleteWorkflowFilter) {
      return tableHeaders.filter(data_21 => [TYPE, MEMBER, COMPLETED_ON].includes(data_21.label));
    }
    if (selectedOsDashboardFilter.filterTabName === OS_NO_RESPONSE_PREDICTED_TAB_NAME && listToMarkComplete.length === 0) {
      return tableHeaders.filter(data_22 => ![COUNT_SELECTED, MARK_COMPLETE].includes(data_22.label));
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOsDashboardFilter.filterTabName, tableHeaders, isCompleteWorkflowFilter, listToMarkComplete.length]);
  const filteredHeaders = useCallback((): TableHeader[] => {
    if (dashboardType === DashboardType.Coach) {
      const coachTableHeaders = getCoachTableHeaders();
      if (coachTableHeaders) {
        return coachTableHeaders;
      }
    }
    if (dashboardType === DashboardType.PhysicalTherapist) {
      return getPtTableHeaders();
    }
    if (dashboardType === DashboardType.OnboardingSpecialist) {
      const osTableHeaders = getOsTableHeaders();
      if (osTableHeaders) {
        return osTableHeaders;
      }
    }
    return tableHeaders.filter(data_23 => [TYPE, MEMBER, CREATED_ON, DUE].includes(data_23.label));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardType, getCoachTableHeaders, getOsTableHeaders, getPtTableHeaders, tableHeaders]);
  const [currentDashboard] = useAtom(selectedDashboardType);
  const sortDirection: Order | undefined = sortWorkflows.order === 'ASC' ? 'DESC' : 'ASC';
  const showFilterIcon = modularIamUnreadMessageTreatment && selectedPtDashboardFilter.filterLabel === PtDashboardFilters.UnreadMessage && currentDashboard === DashboardType.PhysicalTherapist;
  return <React.Fragment>
      <HHTableHead>
        <HHTableRow>
          {filteredHeaders().map(header => header.label !== COACH_WORKFLOWS_TABLE.HEADERS.DUE || !isCompleteWorkflowFilter ? <WorkflowsHeaderCell selectedFilter={selectedDashboardFilter.filterLabel} title={getTitle(header)} direction={sortWorkflows.order ? sortDirection : undefined} key={header.label} showFilterIcon={header.enableFilter && showFilterIcon} /> : null)}
        </HHTableRow>
      </HHTableHead>
      <HHDivider sx={styles.divider} />
    </React.Fragment>;
};
export { WorkflowsHeaderCell, WorkflowsTableHeader };