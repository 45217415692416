import { c as _c } from "react-compiler-runtime";
import { HHButton, HHDialog, HHDialogActions, HHDialogContent, HHDialogContentText, HHDialogTitle } from '@hinge-health/react-component-library';
import { useHHCreateLayoutTabContext } from '@hinge-health/react-component-library/dist/components/molecules/hh-layout-panel';
import { CANCEL_CONFIRMATION } from '../constants/create-workflow-forms';
export interface CancelConfirmationDialogProps {
  open: boolean;
  onCancel: () => void;
  onContinue?: () => void;
}
export const CANCEL_CONFIRMATION_DIALOG_TEST_ID = 'cancel-confirmation-dialog';
export const CancelConfirmationDialog = t0 => {
  const $ = _c(15);
  const {
    open,
    onCancel,
    onContinue
  } = t0;
  const {
    onClose
  } = useHHCreateLayoutTabContext();
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = <HHDialogTitle>{CANCEL_CONFIRMATION.TITLE}</HHDialogTitle>;
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  let t2;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = <HHDialogContent><HHDialogContentText width={CANCEL_CONFIRMATION.DIALOG_WIDTH}>{CANCEL_CONFIRMATION.CONTENT}</HHDialogContentText></HHDialogContent>;
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  let t3;
  if ($[2] !== onCancel) {
    t3 = <HHButton hhVariant="text" color="secondary" onClick={onCancel}>{CANCEL_CONFIRMATION.CANCEL}</HHButton>;
    $[2] = onCancel;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  let t4;
  if ($[4] !== onContinue || $[5] !== onClose) {
    t4 = () => {
      onContinue && onContinue();
      onClose();
    };
    $[4] = onContinue;
    $[5] = onClose;
    $[6] = t4;
  } else {
    t4 = $[6];
  }
  let t5;
  if ($[7] !== t4) {
    t5 = <HHButton hhVariant="text" color="error" onClick={t4}>{CANCEL_CONFIRMATION.DISCARD}</HHButton>;
    $[7] = t4;
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  let t6;
  if ($[9] !== t3 || $[10] !== t5) {
    t6 = <HHDialogActions>{t3}{t5}</HHDialogActions>;
    $[9] = t3;
    $[10] = t5;
    $[11] = t6;
  } else {
    t6 = $[11];
  }
  let t7;
  if ($[12] !== open || $[13] !== t6) {
    t7 = <HHDialog data-testid={CANCEL_CONFIRMATION_DIALOG_TEST_ID} open={open}>{t1}{t2}{t6}</HHDialog>;
    $[12] = open;
    $[13] = t6;
    $[14] = t7;
  } else {
    t7 = $[14];
  }
  return t7;
};