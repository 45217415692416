export const ON = 'on';
export const OFF = 'off';
export const NAV_SPLIT_TREATMENTS = {
  WORKFLOWS_QUEUE: 'workflows-queue',
  THREE_PANEL_LAYOUT: 'three-panel-layout',
  HEALTH_LOG_PAIN_UPTICKS: 'health-log-pain-upticks',
  COACH_CALLS: 'coach-calls',
  MEDIUM_TIER_RETENTION_WORKFLOWS: 'medium-tier-retention-workflows',
  REENGAGER_WORKFLOW_MIGRATION: 'reengager-workflow-migration',
  ENSO_WEEK_1_WORKFLOWS: 'enso-week-1-workflows',
  PT_DASHBOARD: 'pt-dashboard',
  CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT: 'care-coordination-workflow-panel',
  FIRST_WEEK_ENSO_CHECK_IN_WORKFLOW_MIGRATION: 'first-week-enso-check-in-workflow-migration',
  WEEKLY_ENSO_REVIEW_WORKFLOW_MIGRATION: 'weekly-enso-review-workflow-migration',
  ENCOUNTERS_TAB: 'encounters-tab',
  PT_WORKFLOWS_QUEUE: 'pt-workflows-queue',
  PT_MEMBER_ALERTS_REMINDERS: 'pt-member-alerts',
  PHI_MASKING_SWITCH_BUTTON: 'runtime-phi-masking-switch-button',
  CTT_DEPRECATION_VIDEO_VISIT: 'ctt-deprecation-video-visit',
  SETUP_ACTION_PLAN_WORKFLOW: 'setup-action-plan-workflow',
  MIXPANEL_CLICK_TRACKING: 'mixpanel-click-tracking',
  INDICATION_UPDATE_WORKFLOW: 'indication-update-workflow',
  OS_ONBOARDING: 'os-onboarding',
  USER_FEEDBACK: 'user-feedback'
};