export const USER_FEEDBACK = {
  FEEDBACK_PERSIST_KEY: 'FEEDBACK_PERSIST',
  BUG_STRING: 'Bug',
  REQUEST_STRING: 'Request',
  REQUEST_STRING_JIRA: 'Product/design request',
  SUCCESS_ALERT_PREFIX: 'Thank you for your feedback! Our team will review your ticket (',
  SUCCESS_ALERT_SUFFIX: ') shortly',
  SUCCESS_TITLE: 'Feedback submitted',
  DIALOG_TEXTS: {
    FROM_TITLE: 'Share Feedback',
    MEMBER_ID_TITLE: 'Member UUID(s) impacted (optional)',
    MEMBER_ID_PLACEHOLDER: 'Member UUID',
    TITLE_BUG: 'Title of the bug',
    TITLE_BUG_PLACEHOLDER: 'Provide a title for the bug.',
    TITLE_FEATURE: 'Title of the feature request',
    TITLE_FEATURE_PLACEHOLDER: 'Provide a title for the feature request.',
    DESCRIPTION: 'What happen and what did you expect to happen?',
    TITLE_2_BUG_PLACEHOLDER: 'Describe the issue and steps to reproduce and what did you expect to happen',
    TITLE_2_FEATURE: 'What new feature would you like to see and why do you think it is important?',
    TITLE_2_FEATURE_PLACEHOLDER: 'Describe in details',
    TITLE_BUG_REQUEST_QUES: 'Is it a bug or product/design request?',
    ATTACHMENT_TEXT: 'Attachment (optional)'
  },
  FORMATTED_DESC_TEMPLATES: {
    FIRST_LINE: 'Is this a bug or product design?',
    FIRST_LINE_ANS: 'Answer :',
    MEMBER_ID_PREFIX: 'Number of Users Affected: '
  },
  UPLOAD_FILES_TEXT: 'Upload files',
  SUBMIT_FORM_BUTTON_TEXT: 'Submit',
  SUBMIT_ANOTHER_BUTTON_TEXT: 'Submit Another',
  DISCLAIMER_TEXT: 'Avoid uploading anything containing PHI data',
  TICKET_LABEL: {
    BUG: 'feedback_bug',
    REQUEST: 'feedback_request'
  },
  JIRA_BASE_TICKET_URL: 'https://hingehealth.atlassian.net/browse/'
};