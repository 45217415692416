import { WorkflowTypes } from '../../../custom-types';
export enum CoachWorkflowTypes {
  FirstActionPlan = 'first-action-plan',
  UnreadMessages = 'unread-messages',
  UnreadMessagesNoResponse = 'unread-messages-no-response',
  MemberAlertReminder = 'member-alert-reminder',
  ActionPlanReview = 'action-plan-review',
  FirstWeekGoal = 'first-week-goal',
  PregnancyStatusChange = 'pregnancy-status-change',
  OfferVideoVisit = 'offer-video-visit',
  MemberHealthLog = 'member-health-log',
  MemberHealthLogNoResponse = 'member-health-log-No-Response',
  MemberPainUptick = 'member-pain-uptick',
  MaternityMilestones = 'maternity-milestones',
  ActionPlanCheckIn = 'action-plan-check-in',
  FirstWeekCheckIn = 'first-week-check-in',
  CoachCall = 'coach-call',
  EnsoWeek1 = 'enso-week-1',
  ReEngager = 'reengager',
  BalanceAssessment = 'balance-assessment',
  CoachEscalation = 'coach-escalation',
  WeeklyEnsoReview = 'weekly-enso-review',
  OutreachNeeded = 'outreach-needed',
  CoachWeeklyGoalExpiry = 'coach-weekly-goal-expiry',
  CoachMemberUpdatedWeeklyGoal = 'coach-member-updated-weekly-goal',
}
export enum CoachWorkflowsSplitFlagged {
  MemberHealthLog = 'member-health-log',
  MemberPainUptick = 'member-pain-uptick',
}
export enum CommonCoachState {
  CoachComplete = 'complete',
  CoachDismiss = 'dismiss',
  CoachAwaitingNew = 'awaiting-new',
  CoachAwaitingPlan = 'awaiting-plan',
  CoachArchive = 'archive',
  CoachClosed = 'closed',
  CoachOutreachSentToMember = 'outreach-sent-to-member',
  CoachDismissed = 'dismissed',
  CoachPending = 'pending',
  CoachVideoVisitAlreadyScheduledOrComplete = 'video-visit-already-scheduled-or-complete',
  Active = 'active',
  Closed = 'closed',
  CoachUserTransitionedToNewProgram = 'user-transitioned-to-new-program',
  AwaitingMessage = 'awaiting-message',
  AwaitingTag = 'awaiting-tag',
  PostFwg = 'post-fwg',
  CoachVideoVisitCancelVideoVisit = 'cancel-video-visit',
  EnsoAssignedToWorkflow = 'assigned-to-workflow',
  EnsoOrdered = 'enso-ordered',
}
export enum CoachWorkflowState {
  AwaitingNew = 'awaiting-new',
  AwaitingPlan = 'awaiting-plan',
  NoPlan = 'no-plan',
  NewPlan = 'new-plan',
  New = 'new',
  PendingCreation = 'action-plan-pending-creation',
  PendingCreationImmediate = 'action-plan-pending-creation-immediate',
  PendingReview = 'action-plan-pending-review',
  AdminCreatedPlan = 'admin-created-action-plan',
  MemberUpdatedPlan = 'member-updated-action-plan',
  NewHealthLogNote = 'new-health-log-note',
  NewPainUptick = 'new-pain-uptick',
  Active = 'active',
  Closed = 'closed',
  ReviewActionPlanFwg = 'review-action-plan-fwg',
  ReviewActionPlanFwgImmediate = 'review-action-plan-fwg-immediate',
  MemberCreatedNextActionPlanPostFwg = 'member-created-next-action-plan-post-fwg',
  Open = 'open',
  Dismiss = 'dismiss',
}
export enum CoachDashboardFilters {
  All = 'All',
  UnreadMessages = 'Unread messages',
  Completed = 'Completed',
  MemberHealthLog = 'Health log notes',
  ReEngager = 'Re-engager',
  MemberHealthLogNoResponse = 'Health log - notes (No Response)',
  UnreadMessagesNoResponse = 'Unread messages (No Response)',
  ActionPlanCheckIn = 'Action plan check-in',
  FirstWeekCheckIn = 'First week check-in',
  CoachCalls = 'Coach Calls',
  CoachCallsPostCallTasks = 'Coach Calls : Post Call Tasks',
  CoachCallsUpcomingCoachCalls = 'Coach Calls : Upcoming Coach Calls',
  EnsoWeek1 = 'Initial Enso Outreach',
  CoachEscalation = 'Coach escalation',
  WeeklyEnsoReview = 'Weekly Enso review',
  OutreachNeeded = 'Outreach needed',
  AlertReminder = 'Alert reminder',
  MaternityMilestones = 'Maternity milestones',
  PregnancyStatusChange = 'Pregnancy status change',
  ReviewAndRecalibrate = 'Review and recalibrate',
  PainUptick = 'Pain uptick',
  BalanceAssessment = 'Balance assessment',
  FirstWeekReview = 'First week review',
  CoachWeeklyGoalExpiry = 'Review plan',
  CoachMemberUpdatedWeeklyGoal = 'Member created plan',
}
export const coachCallFilters = [CoachDashboardFilters.CoachCalls, CoachDashboardFilters.CoachCallsPostCallTasks, CoachDashboardFilters.CoachCallsUpcomingCoachCalls];
export const commonExcludedStates: CommonCoachState[] = [CommonCoachState.CoachComplete, CommonCoachState.CoachDismiss, CommonCoachState.CoachAwaitingNew, CommonCoachState.CoachAwaitingPlan, CommonCoachState.CoachArchive, CommonCoachState.CoachClosed, CommonCoachState.CoachOutreachSentToMember, CommonCoachState.CoachDismissed, CommonCoachState.CoachUserTransitionedToNewProgram, CommonCoachState.CoachPending, CommonCoachState.CoachVideoVisitAlreadyScheduledOrComplete, CommonCoachState.Closed, CommonCoachState.AwaitingMessage, CommonCoachState.AwaitingTag, CommonCoachState.PostFwg, CommonCoachState.CoachVideoVisitCancelVideoVisit, CommonCoachState.EnsoAssignedToWorkflow, CommonCoachState.EnsoOrdered];
export const commonCompletedStates: CommonCoachState[] = [CommonCoachState.CoachComplete, CommonCoachState.CoachDismiss, CommonCoachState.CoachClosed, CommonCoachState.CoachDismissed, CommonCoachState.CoachOutreachSentToMember, CommonCoachState.CoachUserTransitionedToNewProgram, CommonCoachState.CoachVideoVisitCancelVideoVisit];
export const defaultCoachWorkflows = [CoachWorkflowTypes.FirstActionPlan, CoachWorkflowTypes.UnreadMessages, CoachWorkflowTypes.MemberAlertReminder, CoachWorkflowTypes.ActionPlanReview, CoachWorkflowTypes.FirstWeekGoal, CoachWorkflowTypes.PregnancyStatusChange, CoachWorkflowTypes.OfferVideoVisit, CoachWorkflowTypes.MaternityMilestones, CoachWorkflowTypes.FirstWeekCheckIn, CoachWorkflowTypes.ActionPlanCheckIn, CoachWorkflowTypes.BalanceAssessment];
export const taskCoachWorkflows = [CoachWorkflowTypes.FirstActionPlan, CoachWorkflowTypes.MemberAlertReminder, CoachWorkflowTypes.ActionPlanReview, CoachWorkflowTypes.FirstWeekGoal, CoachWorkflowTypes.PregnancyStatusChange, CoachWorkflowTypes.OfferVideoVisit, CoachWorkflowTypes.MaternityMilestones, CoachWorkflowTypes.FirstWeekCheckIn, CoachWorkflowTypes.ActionPlanCheckIn, CoachWorkflowTypes.BalanceAssessment];
export interface CoachBulkTransition {
  submissionId: string;
  workflowIdsSuccessfullyTransitioned: number[];
  workflowIdsFailedTransition: number[];
  adminUUIDs: string[];
  type: string;
  workflowType: WorkflowTypes;
}
export type CountWorkflowsWithWorkflowTypesQueryResults = {
  state: string;
  count: number;
  workflowType: string;
};