import { c as _c } from "react-compiler-runtime";
import { ctpTokens, HHGrid, HHTypography } from '@hinge-health/react-component-library';
import { containerStyles } from './workflow-filter-styles';
export interface WorkflowFilterItemProps<T> {
  label: T;
  count?: number | string;
  onClick: (label: T) => void;
  isSelected?: boolean;
  isDisabled?: boolean;
  isCategoryHeader?: boolean;
}
export const FILTER_ITEM_ID = 'workflowFilterItem';
const disableStyles: React.CSSProperties = {
  cursor: 'not-allowed',
  opacity: 0.5,
  pointerEvents: ('none' as const)
};
type SxProps = {
  [key: string]: string | number | SxProps;
};
const styles = {
  containerPadding: (isCategoryHeader?: boolean): React.CSSProperties => ({
    padding: `10px 10px 10px ${isCategoryHeader ? '18px' : '6px'}`
  }),
  selectedStyles: (isSelected?: boolean): React.CSSProperties => isSelected ? {
    backgroundColor: ctpTokens.palette.sand.shades['30p'],
    borderRadius: '4px'
  } : {},
  disabledStyles: (isDisabled?: boolean, count?: number | string): React.CSSProperties => isDisabled || count === 0 ? disableStyles : {},
  hoverStyles: (isSelected?: boolean): SxProps => !isSelected ? {
    '&:hover': {
      backgroundColor: ctpTokens.palette.sand.shades['30p'],
      '& .countHovered': {
        backgroundColor: ctpTokens.palette.neutral.shades.primary['12p']
      }
    }
  } : {},
  typographyStyles: (count?: number | string, isSelected?: boolean): React.CSSProperties => count ? {
    backgroundColor: isSelected ? ctpTokens.palette.navy['0'] : ctpTokens.palette.sand['70'],
    borderRadius: '4rem',
    color: isSelected ? ctpTokens.palette.navy['100'] : ctpTokens.palette.navy['0'],
    padding: '0.125rem 0.25rem',
    display: 'inline-block',
    minWidth: '1rem'
  } : {}
};
export const WorkflowFilterItem = t0 => {
  const $ = _c(36);
  const {
    label,
    count,
    onClick,
    isSelected,
    isDisabled,
    isCategoryHeader
  } = t0;
  let displayCount;
  if (isDisabled) {
    displayCount = "-";
  } else {
    if (count === 0) {
      displayCount = "";
    } else {
      displayCount = count;
    }
  }
  let t1;
  if ($[0] !== onClick || $[1] !== label) {
    t1 = () => onClick(label);
    $[0] = onClick;
    $[1] = label;
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  let t2;
  if ($[3] !== isCategoryHeader) {
    t2 = styles.containerPadding(isCategoryHeader);
    $[3] = isCategoryHeader;
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  let t3;
  if ($[5] !== isSelected) {
    t3 = styles.selectedStyles(isSelected);
    $[5] = isSelected;
    $[6] = t3;
  } else {
    t3 = $[6];
  }
  let t4;
  if ($[7] !== isDisabled || $[8] !== count) {
    t4 = styles.disabledStyles(isDisabled, count);
    $[7] = isDisabled;
    $[8] = count;
    $[9] = t4;
  } else {
    t4 = $[9];
  }
  let t5;
  if ($[10] !== isSelected) {
    t5 = styles.hoverStyles(isSelected);
    $[10] = isSelected;
    $[11] = t5;
  } else {
    t5 = $[11];
  }
  let t6;
  if ($[12] !== t2 || $[13] !== t3 || $[14] !== t4 || $[15] !== t5) {
    t6 = {
      ...containerStyles,
      ...t2,
      ...t3,
      ...t4,
      ...t5
    };
    $[12] = t2;
    $[13] = t3;
    $[14] = t4;
    $[15] = t5;
    $[16] = t6;
  } else {
    t6 = $[16];
  }
  let t7;
  if ($[17] !== isSelected) {
    t7 = isSelected && {
      fontWeight: "bold"
    };
    $[17] = isSelected;
    $[18] = t7;
  } else {
    t7 = $[18];
  }
  let t8;
  if ($[19] !== t7) {
    t8 = {
      ...t7
    };
    $[19] = t7;
    $[20] = t8;
  } else {
    t8 = $[20];
  }
  let t9;
  if ($[21] !== t8 || $[22] !== label) {
    t9 = <HHGrid item={true} xs={9}><HHTypography hhVariant="inline" sx={t8}>{label}</HHTypography></HHGrid>;
    $[21] = t8;
    $[22] = label;
    $[23] = t9;
  } else {
    t9 = $[23];
  }
  let t10;
  if ($[24] === Symbol.for("react.memo_cache_sentinel")) {
    t10 = {
      textAlign: "center"
    };
    $[24] = t10;
  } else {
    t10 = $[24];
  }
  let t11;
  if ($[25] !== count || $[26] !== isSelected) {
    t11 = styles.typographyStyles(count, isSelected);
    $[25] = count;
    $[26] = isSelected;
    $[27] = t11;
  } else {
    t11 = $[27];
  }
  let t12;
  if ($[28] !== t11 || $[29] !== displayCount) {
    t12 = <HHGrid item={true} xs={3} sx={t10}><HHTypography hhVariant="inline" className="countHovered" fontSize={12} sx={t11}>{displayCount}</HHTypography></HHGrid>;
    $[28] = t11;
    $[29] = displayCount;
    $[30] = t12;
  } else {
    t12 = $[30];
  }
  let t13;
  if ($[31] !== t1 || $[32] !== t6 || $[33] !== t9 || $[34] !== t12) {
    t13 = <HHGrid container={true} onClick={t1} spacing={0} data-testid={FILTER_ITEM_ID} sx={t6}>{t9}{t12}</HHGrid>;
    $[31] = t1;
    $[32] = t6;
    $[33] = t9;
    $[34] = t12;
    $[35] = t13;
  } else {
    t13 = $[35];
  }
  return t13;
};