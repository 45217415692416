const DEFAULT_WIDTH = 240;
const PANEL_COLOR = '#f5f3ec';
export const filterStyles = {
  drawer: {
    width: DEFAULT_WIDTH
  },
  paperProps: {
    sx: {
      marginLeft: 14,
      zIndex: 0,
      width: DEFAULT_WIDTH,
      backgroundColor: PANEL_COLOR
    }
  },
  accordion: {
    backgroundColor: PANEL_COLOR,
    boxShadow: 'none',
    '&.MuiAccordion-root::before': {
      opacity: 0
    }
  },
  expand: {
    marginRight: 4,
    marginLeft: 4
  },
  divider: {
    margin: '10px 0 10px 0'
  }
};
export const titleStyles = {
  marginLeft: '16px',
  marginTop: '28px',
  marginBottom: '28px'
};
export const listContainerStyles = {
  paddingX: '4px'
};
export const containerStyles = {
  paddingLeft: '16px',
  minHeight: '30px',
  alignItems: 'center',
  padding: '10px 10px 10px 18px',
  cursor: 'pointer'
};
export const disableStyles = {
  cursor: 'not-allowed',
  opacity: 0.5,
  pointerEvents: 'none'
};