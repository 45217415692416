/* eslint-disable @typescript-eslint/explicit-function-return-type */ // return type breaks type inference
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Admin, User, WorkflowPayload } from '../../types';

/* eslint-disable @typescript-eslint/naming-convention */
export interface IEventable {
  build(): Record<string, unknown>;
}
export interface INamedEvent {
  eventName: string;
}
type Constructor<T> = new (...args: any[]) => T;

// ref: https://stackoverflow.com/a/64493510
type GetProps<TBase> = TBase extends new (props: infer P) => any ? P : never;
type GetInstance<TBase> = TBase extends new (...args: any[]) => infer I ? I : never;
type MergeCtor<A, B> = new (props: GetProps<A> & GetProps<B>) => GetInstance<A> & GetInstance<B>;
type MixinBase = Constructor<IEventable>;
export function BaseAdminEvent<T extends MixinBase>(Base: T) {
  const Derived = class BaseAdminEvent extends (Base as any) implements IEventable {
    adminCtx: Partial<Admin>;
    constructor(props: {
      adminCtx: Partial<Admin>;
      adminOktaRole?: string;
    }) {
      super(props);
      this.adminCtx = props?.adminCtx;
      this.adminOktaRole = props.adminOktaRole;
    }
    build(): Record<string, unknown> {
      return {
        currentAdminUUID: this.adminCtx?.uuid,
        currentAdminID: this.adminCtx?.id,
        currentAdminRole: this.adminCtx?.role,
        currentAdminEmail: this.adminCtx?.email,
        currentAdminOktaRole: this.adminOktaRole,
        ...super.build()
      };
    }
  };
  return (Derived as MergeCtor<typeof Derived, T>);
}
export function BaseMemberEvent<T extends MixinBase>(Base: T) {
  const Derived = class BaseMemberEvent extends (Base as any) implements IEventable {
    memberCtx: Partial<User>;
    constructor(props: {
      memberCtx: Partial<User>;
    }) {
      super(props);
      this.memberCtx = props?.memberCtx;
    }
    build(): Record<string, unknown> {
      return {
        currentMemberUUID: this.memberCtx?.uuid,
        currentMemberProgramType: this.memberCtx?.applicationPathway?.program,
        currentMemberProgramIndication: this.memberCtx?.applicationPathway?.indication,
        ...super.build()
      };
    }
  };
  return (Derived as MergeCtor<typeof Derived, T>);
}
export function BaseWorkflowEvent<T extends MixinBase>(Base: T) {
  const Derived = class BaseWorkflowEvent extends (Base as any) implements IEventable {
    workflowCtx: Partial<WorkflowPayload>;
    constructor(props: {
      workflowCtx: Partial<WorkflowPayload>;
    }) {
      super(props);
      this.workflowCtx = props?.workflowCtx;
    }
    build(): Record<string, unknown> {
      return {
        currentWorkflowID: this.workflowCtx?.id,
        currentWorkflowName: this.workflowCtx?.type,
        currentWorkflowState: this.workflowCtx?.state,
        currentWorkflowCreatedAt: this.workflowCtx?.createdAt,
        currentWorkflowMemberUUID: this.workflowCtx?.patient?.uuid,
        ...super.build()
      };
    }
  };
  return (Derived as MergeCtor<typeof Derived, T>);
}