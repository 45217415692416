// absolute paths
export const ROUTES = {
  ACUTE: '/acute',
  PT: '/pt',
  AUTH_REDIRECT_CALLBACK: '/okta/callback',
  COACH: '/coach',
  OS: '/os',
  HOME: '/',
  LOGIN: '/login',
  MEMBER: '/member',
  OTHER: '/not-found',
  PORTAL: '/portal',
  TEST: '/test',
  COACH_ASSISTANT: '/assistant',
  MASS_MESSAGE_LIST: '/mass-message-list',
  MASS_MESSAGE: '/mass-message',
  MEMBER_SEARCH: '/member-search'
};

// do not use absolute paths for nested routes
export const NESTED_ROUTES = {
  ACTIVITY: 'activity',
  CARE_PLAN: 'care-plan',
  ID: ':id',
  PROFILE: 'profile',
  DAILY_ACTIVITY: 'daily-activity',
  INTERACTIONS: 'interactions',
  EXERCISE_THERAPY: 'exercise-therapy',
  NOTES: 'notes',
  VIDEO_VISIT: 'video-visit'
};
export const QUERY_PARAMETERS = {
  DATE: 'date'
};