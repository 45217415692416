import { CoachWorkflowTypes } from '../../custom-types';
export const WORKFLOW_LIST_HEADER_TITLE: Record<CoachWorkflowTypes, string> = {
  [CoachWorkflowTypes.FirstActionPlan]: 'Create action plan',
  [CoachWorkflowTypes.UnreadMessages]: 'Unread message',
  [CoachWorkflowTypes.MemberAlertReminder]: 'Alert reminder',
  [CoachWorkflowTypes.ActionPlanReview]: 'Review and recalibrate',
  [CoachWorkflowTypes.FirstWeekGoal]: 'Review and recalibrate',
  [CoachWorkflowTypes.PregnancyStatusChange]: 'Pregnancy Status Change',
  [CoachWorkflowTypes.OfferVideoVisit]: 'Offer Video Visit',
  [CoachWorkflowTypes.MemberHealthLog]: 'Health log',
  [CoachWorkflowTypes.MemberPainUptick]: 'Health log',
  [CoachWorkflowTypes.MaternityMilestones]: 'Maternity Milestones',
  [CoachWorkflowTypes.ActionPlanCheckIn]: 'Action plan check-in',
  [CoachWorkflowTypes.FirstWeekCheckIn]: 'First week check-in',
  [CoachWorkflowTypes.CoachCall]: 'Coach call',
  [CoachWorkflowTypes.ReEngager]: 'Re-engager',
  [CoachWorkflowTypes.CoachEscalation]: 'Coach escalation',
  [CoachWorkflowTypes.EnsoWeek1]: 'Initial Enso outreach',
  [CoachWorkflowTypes.WeeklyEnsoReview]: 'Weekly Enso review',
  [CoachWorkflowTypes.OutreachNeeded]: 'Outreach needed'
};
export const WORKFLOW_CARD_PRIMARY_TEXT = {
  NO_PLAN: 'No plan created',
  CREATE_BY_MEMBER: 'Member created plan',
  CREATE_BY_ADMIN: 'Care team created plan',
  REVIEW_PLAN: 'Review plan',
  MEMBER_CREATED_PLAN: 'Member created plan'
};
export const WORKFLOW_CLOSING_ACTION_TEXT = 'Closing action';
export const WORKFLOW_COMPLETE_TEXT = 'Complete';
export const WORKFLOW_COUNT_TEXT = 'Incomplete tasks';
export const WORKFLOW_RESOLUTION_TEXT = 'Resolving workflow';