import { c as _c } from "react-compiler-runtime";
import React from 'react';
import { useLocation } from 'react-router-dom';
export default function useQueryParams() {
  const $ = _c(2);
  const {
    search
  } = useLocation();
  let t0;
  let t1;
  if ($[0] !== search) {
    const urlSearchParams = new URLSearchParams(search);
    const keys = [...urlSearchParams.keys()];
    t1 = keys.reduce((a, e) => {
      a[(e as keyof T)] = (urlSearchParams.get(e) as T[keyof T]);
      return a;
    }, ({} as T));
    $[0] = search;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  t0 = t1;
  return t0;
}