export const MEMBER_SEARCH_PAGE = {
  ERROR: 'Error loading the page while fetching admin',
  MEMBER_TABLE_ERROR: 'Unable to load to display.',
  MEMBER_SEARCH: 'Member Search',
  LOADER: 'member-search-page-loader',
  FALLBACK_ERROR: 'Unable to load to display',
  MEMBERS: 'Members',
  SEARCH_BY: 'Search by',
  TYPE: 'Type',
  PHONE_NUMBER_INPUT_ERROR: 'Please enter a valid phone number in digits only',
  MEMBER_ID_INPUT_ERROR: 'Please enter a valid member id in digits only',
  EMAIL_INPUT_ERROR: 'Please enter a valid email',
  POPUP_TITLE: 'isn’t under your coverage',
  POPUP_CONTENT: 'Are you sure you want to view this member?',
  CANCEL: 'Cancel',
  VIEW_MEMBER: 'View Member',
  SHOW_MORE: 'Show more',
  COVERAGE: 'coverage',
  QUERY_ERROR: 'No results found. Please check your search query.',
  TOP: 'Top',
  PER_PAGE: '9',
  CHIP_FILLED: 'MuiChip-filled',
  CHIP_OUTLINED: 'MuiChip-outlined'
};
export const MEMBER_SEARCH_ATTRIBUTE = {
  MEMBER_NAME: 'Member name',
  MEMBER_ID: 'Member ID',
  EMAIL: 'Email',
  PHONE_NUMBER: 'Phone number',
  TAG: 'Tag',
  EMPLOYER: 'Employer',
  COVERED_UNDER_ADMIN: 'isCoveredUnderCurrentAdmin',
  CONTACT: 'Contact',
  SECURE_ID: 'Secure ID',
  ID: 'ID(s)'
};
export const MEMBER_SEARCH_DATA_TESTID = {
  INPUT_FIELD: 'member-search-input-field',
  DROPDOWN: 'member-search-dropdown',
  ALL_CHIP: 'member-search-chip-all',
  MY_MEMBER_CHIP: 'member-search-chip-my-member',
  OTHER_MEMBER_CHIP: 'member-search-chip-other-member',
  SCROLL_TO_TOP: 'member-search-scroll-to-top-button',
  SHOW_MORE: 'member-search-show-more-button',
  SEARCH: 'member-search-button',
  TABLE_ROW: 'body-tableRow',
  POPUP_TITLE: 'popup-title',
  POPUP_CONTENT: 'popup-content',
  POPUP_CANCEL_BUTTON: 'popup-cancel-button',
  POPUP_VIEW_MEMBER_BUTTON: 'popup-view-member-button',
  MEMBER_AVATAR: 'member-avatar',
  SPINNER: 'member-search-table-circular-progress',
  ERROR: 'member-search-error',
  CONFIRM_BOX: 'confirm-box',
  BOLD_CHAR: 'member-search-bold',
  TABLE_CONTAINER: 'table-container'
};
export const SEARCH_FILTER_OPTIONS = ['Member name', 'Member ID', 'Email', 'Phone number', 'Employer', 'Secure ID'];
export const SEARCH_FILTER_PLACEHOLDER_MAP: Map<string, string> = new Map([['Member name', 'a member name'], ['Member ID', 'a complete member ID'], ['Email', 'a complete email id'], ['Phone number', 'a complete phone number with country code'], ['Tag', 'a member tag'], ['Employer', 'an employer name'], ['Secure ID', 'a complete secure ID']]);
export const SEARCH_QUERY_FIELD_MAP: Map<string, string> = new Map([['Member name', 'name'], ['Member ID', 'userIds'], ['Email', 'emails'], ['Phone number', 'phoneNumbers'], ['Tag', 'tags'], ['Employer', 'clientName'], ['Secure ID', 'userUUIDs']]);
export const SEARCH_COVERAGE_FILTER_LABEL = {
  ALL: 'All',
  MY_MEMBERS: 'My members',
  OTHER_MEMBERS: 'Other members'
};
export enum MemberSearchCoverageFilter {
  All = 'all',
  Within = 'within',
  Outside = 'without',
}
export const SEARCH_INPUT_FILTER_FIELDS = ['emails', 'tags', 'phoneNumbers', 'userUUIDs', 'userIds'];
export enum ColumnName {
  MemberName = 'memberName',
  Tags = 'tags',
  Contact = 'contact',
  Employer = 'employer',
  MemberIds = 'ids',
}
export const PHI_EVENT = {
  PHI_MASK_ENABLE: 'carehub_home.left_navigation_pane.phi_turned_on',
  PHI_MASK_DISABLE: 'carehub_home.left_navigation_pane.phi_turned_off'
};
export const SEARCH_MIXPANEL_EVENTS = {
  CLICK_MEMBER_SEARCH: 'carehub_home.left_navigation_pane.member_search_icon_clicked',
  SELECT_FILTER: 'search.search_result.filter',
  SELECT_COVERAGE_FILTER: 'search.search_result.coverage_filter',
  CLEAR_SEARCH_INPUT: 'search.search_result.clear_search_input',
  SEARCH_BUTTON_CLICKED: 'search.search_field.search_button_clicked',
  SHOW_MORE: 'search.search_result.show_more',
  SCROLL_TO_TOP: 'search.search_result.scroll',
  VIEW_MY_MEMBER: 'member_search.search_result.clicked',
  DASHBOARD: 'carehub_home.left_navigation_pane.dashboard_icon_clicked',
  HELPSCOUT_ICON_CLICKED: 'carehub_home.left_navigation_pane.helpscout_icon_clicked',
  FRESHDESK_ICON_CLICKED: 'carehub_home.left_navigation_pane.freshdesk_icon_clicked',
  MASS_MESSAGE_ICON: 'carehub_home.left_navigation_pane.mass_messenger_icon_clicked',
  MY_TEAMS: 'carehub_home.left_navigation_pane.my_teams_icon_clicked',
  LOGOUT: 'carehub_home.left_navigation_pane.logout_icon_clicked',
  VIEW_OTHER_MEMBER: 'search.search_result.search_result_selected',
  CANCEL_CONFIRMATION: 'search.search_result.confirmation_popup.cancel_button_clicked'
};
export const MEMBER_SEARCH_FETCH_ERROR = 'Error fetching member search data';
export const MEMBER_SEARCH_HIGHLIGHT_ERROR = 'Error in highlighting member search match';