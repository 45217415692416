import { Admin, User, WorkflowPayload } from '../../../types';
import { BaseEvent } from '../base-event';
import { BaseAdminEvent, BaseMemberEvent, BaseWorkflowEvent } from '../mixins';
import { MIXPANEL_EVENTS } from '../mixpanel-event-names';
import { Scalar } from '../types/scalar';
const BaseWorkflowSelectedEvent = BaseAdminEvent(BaseEvent);
const BaseWorkflowPanelEvent = BaseWorkflowEvent(BaseMemberEvent(BaseAdminEvent(BaseEvent)));
export class WorkflowSelectedFromDashboard extends BaseWorkflowSelectedEvent {
  eventName = MIXPANEL_EVENTS.WORKFLOW_SELECTED_FROM_DASHBOARD;
  constructor(props: {
    selectedWorkflowID: Scalar['ID'];
    selectedWorkflowName: Scalar['String'];
    selectedWorkflowState: Scalar['String'];
    selectedWorkflowCreatedAt: Scalar['String'];
    selectedWorkflowMemberUUID: Scalar['String'];
  } & {
    adminCtx: Partial<Admin>;
    eventVersion?: string;
  }) {
    super(props);
    this.payload = {
      selectedWorkflowID: props?.selectedWorkflowID,
      selectedWorkflowName: props?.selectedWorkflowName,
      selectedWorkflowState: props?.selectedWorkflowState,
      selectedWorkflowCreatedAt: props?.selectedWorkflowCreatedAt,
      selectedWorkflowMemberUUID: props?.selectedWorkflowMemberUUID
    };
  }
  build(): Record<string, unknown> {
    return {
      ...this.payload,
      ...super.build()
    };
  }
}
export class WorkflowSelectedFromQueue extends BaseWorkflowSelectedEvent {
  eventName = MIXPANEL_EVENTS.WORKFLOW_SELECTED_FROM_QUEUE;
  constructor(props: {
    selectedWorkflowID: Scalar['ID'];
    selectedWorkflowName: Scalar['String'];
    selectedWorkflowState: Scalar['String'];
    selectedWorkflowCreatedAt: Scalar['String'];
    selectedWorkflowMemberUUID: Scalar['String'];
  } & {
    adminCtx: Partial<Admin>;
    eventVersion?: string;
  }) {
    super(props);
    this.payload = {
      selectedWorkflowID: props?.selectedWorkflowID,
      selectedWorkflowName: props?.selectedWorkflowName,
      selectedWorkflowState: props?.selectedWorkflowState,
      selectedWorkflowCreatedAt: props?.selectedWorkflowCreatedAt,
      selectedWorkflowMemberUUID: props?.selectedWorkflowMemberUUID
    };
  }
  build(): Record<string, unknown> {
    return {
      ...this.payload,
      ...super.build()
    };
  }
}
export class WorkflowSelectedFromWorkflowsList extends BaseWorkflowSelectedEvent {
  eventName = MIXPANEL_EVENTS.WORKFLOW_CLICKED_FROM_LIST;
  constructor(props: {
    selectedWorkflowID: Scalar['ID'];
    selectedWorkflowName: Scalar['String'];
    selectedWorkflowState: Scalar['String'];
    selectedWorkflowCreatedAt: Scalar['String'];
    selectedWorkflowMemberUUID: Scalar['String'];
  } & {
    adminCtx: Partial<Admin>;
    eventVersion?: string;
  }) {
    super(props);
    this.payload = {
      selectedWorkflowID: props?.selectedWorkflowID,
      selectedWorkflowName: props?.selectedWorkflowName,
      selectedWorkflowState: props?.selectedWorkflowState,
      selectedWorkflowCreatedAt: props?.selectedWorkflowCreatedAt,
      selectedWorkflowMemberUUID: props?.selectedWorkflowMemberUUID
    };
  }
  build(): Record<string, unknown> {
    return {
      ...this.payload,
      ...super.build()
    };
  }
}
export class WorkflowSelectedEvent extends BaseWorkflowSelectedEvent {
  eventName = MIXPANEL_EVENTS.WORKFLOW_SELECTED;
  constructor(props: {
    selectedWorkflowID: Scalar['ID'];
    selectedWorkflowName: Scalar['String'];
    selectedWorkflowState: Scalar['String'];
    selectedWorkflowCreatedAt: Scalar['String'];
    selectedWorkflowMemberUUID: Scalar['String'];
  } & {
    adminCtx: Partial<Admin>;
    eventVersion?: string;
  }) {
    super(props);
    this.payload = {
      selectedWorkflowID: props?.selectedWorkflowID,
      selectedWorkflowName: props?.selectedWorkflowName,
      selectedWorkflowState: props?.selectedWorkflowState,
      selectedWorkflowCreatedAt: props?.selectedWorkflowCreatedAt,
      selectedWorkflowMemberUUID: props?.selectedWorkflowMemberUUID
    };
  }
  build(): Record<string, unknown> {
    return {
      ...this.payload,
      ...super.build()
    };
  }
}
export class CareCoordinationWorkflowFormSubmitted extends BaseWorkflowPanelEvent {
  eventName = MIXPANEL_EVENTS.CARE_COORDINATION_WORKFLOW_FORM_SUBMITTED;
  constructor(props: {
    careCoordinationWorkflowName: Scalar['String'];
  } & {
    adminCtx: Partial<Admin>;
    memberCtx: Partial<User>;
    workflowCtx: Partial<WorkflowPayload>;
    eventVersion?: string;
  }) {
    super(props);
    this.payload = {
      careCoordinationWorkflowName: props?.careCoordinationWorkflowName
    };
  }
  build(): Record<string, unknown> {
    return {
      ...this.payload,
      ...super.build()
    };
  }
}
export class CareCoordinationWorkflowFormCancelled extends BaseWorkflowPanelEvent {
  eventName = MIXPANEL_EVENTS.CARE_COORDINATION_WORKFLOW_FORM_CANCELLED;
  constructor(props: {
    careCoordinationWorkflowName: Scalar['String'];
  } & {
    adminCtx: Partial<Admin>;
    memberCtx: Partial<User>;
    workflowCtx: Partial<WorkflowPayload>;
    eventVersion?: string;
  }) {
    super(props);
    this.payload = {
      careCoordinationWorkflowName: props?.careCoordinationWorkflowName
    };
  }
  build(): Record<string, unknown> {
    return {
      ...this.payload,
      ...super.build()
    };
  }
}
export class WorkflowResolved extends BaseWorkflowPanelEvent {
  eventName = MIXPANEL_EVENTS.WORKFLOW_RESOLVED;
  constructor(props: {
    adminCtx: Partial<Admin>;
    memberCtx: Partial<User>;
    workflowCtx: Partial<WorkflowPayload>;
    eventVersion?: string;
  }) {
    super(props);
  }
  build(): Record<string, unknown> {
    return {
      ...super.build()
    };
  }
}
export class ResourceLinkClicked extends BaseWorkflowEvent(BaseAdminEvent(BaseEvent)) {
  eventName = MIXPANEL_EVENTS.RESOURCE_LINK_CLICKED;
  constructor(props: {
    resourceLink: Scalar['String'];
  } & {
    adminCtx: Partial<Admin>;
    workflowCtx: Partial<WorkflowPayload>;
    eventVersion?: string;
  }) {
    super(props);
    this.payload = {
      resourceLink: props?.resourceLink
    };
  }
  build(): Record<string, unknown> {
    return {
      ...this.payload,
      ...super.build()
    };
  }
}
export class WorkflowDismissed extends BaseWorkflowPanelEvent {
  eventName = MIXPANEL_EVENTS.WORKFLOW_DISMISSED;
  constructor(props: {
    adminCtx: Partial<Admin>;
    memberCtx: Partial<User>;
    workflowCtx: Partial<WorkflowPayload>;
    eventVersion?: string;
  }) {
    super(props);
  }
  build(): Record<string, unknown> {
    return {
      ...super.build()
    };
  }
}
export class WorkflowReopened extends BaseWorkflowPanelEvent {
  eventName = MIXPANEL_EVENTS.WORKFLOW_REOPENED;
  constructor(props: {
    adminCtx: Partial<Admin>;
    memberCtx: Partial<User>;
    workflowCtx: Partial<WorkflowPayload>;
    eventVersion?: string;
  }) {
    super(props);
  }
  build(): Record<string, unknown> {
    return {
      ...super.build()
    };
  }
}
export class ActionCompleted extends BaseWorkflowPanelEvent {
  eventName = MIXPANEL_EVENTS.ACTION_COMPLETED;
  taskName: Scalar['String'];
  constructor(props: {
    taskName: Scalar['String'];
  } & {
    adminCtx: Partial<Admin>;
    memberCtx: Partial<User>;
    workflowCtx: Partial<WorkflowPayload>;
    eventVersion?: string;
  }) {
    super(props);
    this.payload = {
      taskName: props?.taskName
    };
  }
  build(): Record<string, unknown> {
    return {
      ...this.payload,
      ...super.build()
    };
  }
}
export class ActionUndone extends BaseWorkflowPanelEvent {
  eventName = MIXPANEL_EVENTS.ACTION_UNDONE;
  taskName: Scalar['String'];
  constructor(props: {
    taskName: Scalar['String'];
  } & {
    adminCtx: Partial<Admin>;
    memberCtx: Partial<User>;
    workflowCtx: Partial<WorkflowPayload>;
    eventVersion?: string;
  }) {
    super(props);
    this.payload = {
      taskName: props?.taskName
    };
  }
  build(): Record<string, unknown> {
    return {
      ...this.payload,
      ...super.build()
    };
  }
}
export class CareCoordinationWorkflowSelectedFromWorkflowPanel extends BaseWorkflowPanelEvent {
  eventName = MIXPANEL_EVENTS.CARE_COORDINATION_FROM_WORKFLOW_PANEL;
  constructor(props: {
    careCoordinationWorkflowName: Scalar['String'];
  } & {
    adminCtx: Partial<Admin>;
    memberCtx: Partial<User>;
    workflowCtx: Partial<WorkflowPayload>;
    eventVersion?: string;
  }) {
    super(props);
    this.payload = {
      careCoordinationWorkflowName: props?.careCoordinationWorkflowName
    };
  }
  build(): Record<string, unknown> {
    return {
      ...this.payload,
      ...super.build()
    };
  }
}
export class CareCoordinationWorkflowSelectedFromMemberRecord extends BaseWorkflowPanelEvent {
  eventName = MIXPANEL_EVENTS.CARE_COORDINATION_FROM_MEMBER_RECORD;
  constructor(props: {
    careCoordinationWorkflowName: Scalar['String'];
  } & {
    adminCtx: Partial<Admin>;
    memberCtx: Partial<User>;
    workflowCtx: Partial<WorkflowPayload>;
    eventVersion?: string;
  }) {
    super(props);
    this.payload = {
      careCoordinationWorkflowName: props?.careCoordinationWorkflowName
    };
  }
  build(): Record<string, unknown> {
    return {
      ...this.payload,
      ...super.build()
    };
  }
}
export class NextButtonClicked extends BaseAdminEvent(BaseEvent) {
  eventName = MIXPANEL_EVENTS.NEXT_BOTTON_CLICKED;
  constructor(props: {
    workflowID: Scalar['ID'];
    workflowType: Scalar['String'];
  } & {
    adminCtx: Partial<Admin>;
    eventVersion?: string;
  }) {
    super(props);
    this.payload = {
      workflowID: props?.workflowID,
      workflowType: props?.workflowType
    };
  }
  build(): Record<string, unknown> {
    return {
      ...this.payload,
      ...super.build()
    };
  }
}
export class QueueWorkflowCategorySelected extends BaseAdminEvent(BaseEvent) {
  eventName = MIXPANEL_EVENTS.QUEUE_WORKFLOW_CATEGORY_SELECTED;
  constructor(props: {
    selectedWorkflowCategory: Scalar['String'];
  } & {
    adminCtx: Partial<Admin>;
    eventVersion?: string;
  }) {
    super(props);
    this.payload = {
      selectedWorkflowCategory: props?.selectedWorkflowCategory
    };
  }
  build(): Record<string, unknown> {
    return {
      ...this.payload,
      ...super.build()
    };
  }
}
export class NewAdminSelected extends BaseAdminEvent(BaseEvent) {
  eventName = MIXPANEL_EVENTS.NEW_ADMIN_SELECTED;
  constructor(props: {
    shadowedAdminUUID: Scalar['String'];
  } & {
    adminCtx: Partial<Admin>;
    eventSchemaVersion?: string;
  }) {
    super(props);
    this.payload = {
      shadowedAdminUUID: props?.shadowedAdminUUID
    };
  }
  build(): Record<string, unknown> {
    return {
      ...this.payload,
      ...super.build()
    };
  }
}