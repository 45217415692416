import { WorkflowCount } from '../types';
import { HouseCallState } from '../types/house-calls';
import { CommonPtState } from '../types/pt';
import { VideoVisitsState } from '../types/video-visit';
type KnownWorkflowsState = CommonPtState | VideoVisitsState | HouseCallState;
export interface WorkflowResult extends WorkflowCount {
  state: KnownWorkflowsState;
}
interface GetSeparatedWorkflowCounts<T> {
  results: WorkflowResult[];
  workflowTypes: T & {
    [s: string]: unknown;
  };
  excludedStates: KnownWorkflowsState[];
  upcomingStates: KnownWorkflowsState[];
  incompleteStates: KnownWorkflowsState[];
}
type WorkflowTabCounts = {
  all: number;
  upcoming: number;
  incomplete: number;
};
export const getSeparatedWorkflowCounts = <T,>({
  results,
  workflowTypes,
  excludedStates,
  upcomingStates,
  incompleteStates
}: GetSeparatedWorkflowCounts<T>): WorkflowTabCounts => results.reduce((acc, curr) => {
  const isIncludedWorkflowType = Object.values(workflowTypes).includes((curr.workflowType as T));
  const isExcludedState = excludedStates.includes(curr.state);
  if (!isIncludedWorkflowType || isExcludedState) {
    return acc;
  }
  let {
    all,
    upcoming,
    incomplete
  } = acc;
  if (upcomingStates.includes(curr.state)) {
    upcoming += curr.count;
  } else if (incompleteStates.includes(curr.state)) {
    incomplete += curr.count;
  }
  all += curr.count;
  return {
    all,
    upcoming,
    incomplete
  };
}, {
  all: 0,
  upcoming: 0,
  incomplete: 0
});