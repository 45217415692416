import { c as _c } from "react-compiler-runtime";
import { HHBox, HHCircularProgress, HHDivider, HHSnackbarAlert, HHTable, HHTableContainer, HHTypography } from '@hinge-health/react-component-library';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { ON, SPLIT_TREATMENTS } from '../../../constants/splits';
import { DEFAULT_COACH_DASHBOARD_TAB_NAME, LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME } from '../../../constants/strings/coach-dashboard-default';
import { COACH_LAYOUT } from '../../../constants/strings/coach-layout';
import { OS_NO_RESPONSE_PREDICTED_TAB_NAME } from '../../../constants/strings/os-layout';
import { NO_RESPONSE_PREDICTED_TAB_NAME } from '../../../constants/strings/pt-layout';
import { COACH_WORKFLOWS_TABLE, HEALTH_LOG_NOTES_LOAD_ERROR, TASKS_BEING_UPDATED, TASKS_LOAD_ERROR, UNREAD_MESSAGES_LOAD_ERROR } from '../../../constants/strings/workflows-table';
import { useShellContext } from '../../../contexts/shell.context';
import { DashboardType } from '../../../custom-types';
import { useBulkProgressTimeout } from '../../../hooks/use-bulk-progress-timeout';
import { useGetDashboardWorkflows } from '../../../hooks/use-get-dashboard-workflows';
import { bulkWorkflowHLNProgress, bulkWorkflowUnreadProgress, selectedCoachDashboardFilters, selectedDashboardType, selectedOsDashboardFilters, selectedPtDashboardFilters, selectedUnreadType, shouldRefetchWorkflows, showTimeoutAlert, totalAllTypesCount, totalAllTypesCountLowPriority, totalModularIamCount, totalModularIamCountLowPriority, totalUnreadCount, totalUnreadCountLowPriority, totalWorkflowCount, workflowListLoading } from '../../../jotai/atoms';
import { coachCallFilters, CoachDashboardFilters } from '../../../types/coach';
import { OsDashboardFilters } from '../../../types/os';
import { healthLogFilters, houseCallFilters, PtDashboardFilters, unreadMessageFilters, videoVisitFilters } from '../../../types/pt';
import { CoachCallsTabs } from '../coach-calls/coach-calls-tabs';
import { EnsoWeek1Tabs } from '../enso-week-1/enso-week-1-tabs';
import { HealthLogNotesTabs } from '../health-log-notes/health-log-notes-tabs';
import { PtHealthLogNotesTabs } from '../health-log-notes/pt-health-log-notes-tabs';
import { OSUnreadMessagesTabs } from '../unread-messages/os-unread-messages-tabs';
import { PtUnreadMessagesTabs } from '../unread-messages/pt-unread-messages-tabs';
import { UnreadMessagesTabs } from '../unread-messages/unread-messages-tabs';
import { VideoVisitTabs } from '../video-visit-tabs';
import { WorkflowTableTabs } from '../workflow-table-tabs';
import { getHouseCallTabsProps } from './pt-workflows-table-configs';
import { TaskUpdating } from './task-updating';
import WorkflowsPagination from './workflows-pagination';
import { WorkflowsTableContainer } from './workflows-table-container';
import { WorkflowsTableError } from './workflows-table-error';
import { workflowsTableStyles } from './workflows-table-styles';
export const PAGE_SIZE = 10;
export interface WorkflowsTableProps<T extends CoachDashboardFilters | PtDashboardFilters | OsDashboardFilters> {
  counts: Record<T, number>;
}
export const WorkflowsTable = props => {
  const $ = _c(45);
  const {
    counts
  } = props;
  const dashboardType = useAtomValue(selectedDashboardType);
  const [selectedCoachDashboardFilter, setSelectedCoachDashboardFilter] = useAtom(selectedCoachDashboardFilters);
  const [selectedPtDashboardFilter, setSelectedPtDashboardFilter] = useAtom(selectedPtDashboardFilters);
  const [selectedOsDashboardFilter, setSelectedOsDashboardFilter] = useAtom(selectedOsDashboardFilters);
  const [workflowCounts, setWorkflowCounts] = useState(0);
  const [allTypesCountTotal] = useAtom(totalAllTypesCount);
  const [messageCountTotal] = useAtom(totalUnreadCount);
  const [iamMessageCountTotal] = useAtom(totalModularIamCount);
  const [selectedUnreadFilter] = useAtom(selectedUnreadType);
  const {
    workflows,
    error,
    loading: workflowLoading
  } = useGetDashboardWorkflows(dashboardType);
  const showPagination = Boolean(workflowCounts);
  const [workflowListLoadingState, setWorkflowListLoading] = useAtom(workflowListLoading);
  const [openSnackbar, setOpenSnackbar] = useState(true);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = () => setOpenSnackbar(false);
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  const handleSnackbar = t0;
  const ptLabel = (selectedPtDashboardFilter.filterLabel.split(":")[0].trim() as PtDashboardFilters);
  const osLabel = (selectedOsDashboardFilter.filterLabel.split(":")[0].trim() as OsDashboardFilters);
  const splitClient = useClient();
  const {
    adminProfile
  } = useShellContext();
  const unreadMessagesDualTabTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.UNREAD_MESSAGE_ML, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const healthLogNotesSplitFlag = splitClient?.getTreatment(SPLIT_TREATMENTS.HEALTH_LOG_NOTES, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const ptHealthLogNotesDualTabFlag = splitClient?.getTreatment(SPLIT_TREATMENTS.PT_HLN_ML, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const ptUnreadMessagesDualTabFlag = splitClient?.getTreatment(SPLIT_TREATMENTS.PT_UNREAD_MESSAGES_ML, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  let t1;
  let t2;
  if ($[1] !== setWorkflowListLoading || $[2] !== workflowLoading || $[3] !== error) {
    t1 = () => {
      setWorkflowListLoading(workflowLoading && !error);
    };
    t2 = [setWorkflowListLoading, workflowLoading, error];
    $[1] = setWorkflowListLoading;
    $[2] = workflowLoading;
    $[3] = error;
    $[4] = t1;
    $[5] = t2;
  } else {
    t1 = $[4];
    t2 = $[5];
  }
  useEffect(t1, t2);
  const [bulkHLNProgress, setBulkHLNProgress] = useAtom(bulkWorkflowHLNProgress);
  const [bulkUnreadProgress, setUnreadProgress] = useAtom(bulkWorkflowUnreadProgress);
  const [showTimeOutErr] = useAtom(showTimeoutAlert);
  const setTotalCount = useSetAtom(totalWorkflowCount);
  const [totalAllTypesLowPriority] = useAtom(totalAllTypesCountLowPriority);
  const [totalmodularIAMLowPriority] = useAtom(totalModularIamCountLowPriority);
  const [totalUnreadLowPriority] = useAtom(totalUnreadCountLowPriority);
  const [, setShouldRefetchWorkflows] = useAtom(shouldRefetchWorkflows);
  const lowPriorityNotesSplitFlag = unreadMessagesDualTabTreatment || healthLogNotesSplitFlag || ptHealthLogNotesDualTabFlag || ptUnreadMessagesDualTabFlag;
  let t3;
  if ($[6] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = {
      marginTop: "1rem"
    };
    $[6] = t3;
  } else {
    t3 = $[6];
  }
  const lowPriorityStyles = t3;
  let t4;
  if ($[7] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = sessionStorage.getItem("unreadProgress");
    $[7] = t4;
  } else {
    t4 = $[7];
  }
  const unreadProgress = t4;
  let t5;
  if ($[8] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = sessionStorage.getItem("hlnProgress");
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  const hlnProgress = t5;
  window.addEventListener("beforeunload", () => {
    sessionStorage.removeItem("unreadProgress");
    sessionStorage.removeItem("hlnProgress");
  });
  let t6;
  let t7;
  if ($[9] !== setUnreadProgress || $[10] !== setBulkHLNProgress) {
    t6 = () => {
      if (unreadProgress === "true") {
        setUnreadProgress(true);
      }
      if (hlnProgress === "true") {
        setBulkHLNProgress(true);
      }
    };
    t7 = [unreadProgress, hlnProgress, setUnreadProgress, setBulkHLNProgress];
    $[9] = setUnreadProgress;
    $[10] = setBulkHLNProgress;
    $[11] = t6;
    $[12] = t7;
  } else {
    t6 = $[11];
    t7 = $[12];
  }
  useEffect(t6, t7);
  useEffect(() => {
    if (dashboardType === DashboardType.Coach) {
      if ([CoachDashboardFilters.MemberHealthLog, CoachDashboardFilters.MemberHealthLogNoResponse].includes(selectedCoachDashboardFilter.filterLabel)) {
        if (selectedCoachDashboardFilter.filterTabName === LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME) {
          setTotalCount(counts[(CoachDashboardFilters.MemberHealthLogNoResponse as T)]);
          return setWorkflowCounts(counts[(CoachDashboardFilters.MemberHealthLogNoResponse as T)]);
        }
        return setWorkflowCounts(counts[(CoachDashboardFilters.MemberHealthLog as T)]);
      } else {
        if ([CoachDashboardFilters.UnreadMessages, CoachDashboardFilters.UnreadMessagesNoResponse].includes(selectedCoachDashboardFilter.filterLabel)) {
          if (selectedCoachDashboardFilter.filterTabName === LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME) {
            setTotalCount(counts[(CoachDashboardFilters.UnreadMessagesNoResponse as T)]);
            return setWorkflowCounts(counts[(CoachDashboardFilters.UnreadMessagesNoResponse as T)]);
          }
          return setWorkflowCounts(counts[(CoachDashboardFilters.UnreadMessages as T)]);
        }
      }
      return setWorkflowCounts(counts[(selectedCoachDashboardFilter.filterLabel as T)]);
    }
  }, [dashboardType, counts, selectedCoachDashboardFilter.filterLabel, selectedCoachDashboardFilter.filterTabName, setTotalCount, workflows]);
  useEffect(() => {
    if (dashboardType === DashboardType.PhysicalTherapist) {
      if ([PtDashboardFilters.HealthLogNotes, PtDashboardFilters.HealthLogNoResponse].includes(selectedPtDashboardFilter.filterLabel)) {
        if (selectedPtDashboardFilter.filterTabName === NO_RESPONSE_PREDICTED_TAB_NAME) {
          setTotalCount(counts[(PtDashboardFilters.HealthLogNoResponse as T)]);
          return setWorkflowCounts(counts[(PtDashboardFilters.HealthLogNoResponse as T)]);
        }
        return setWorkflowCounts(counts[(PtDashboardFilters.HealthLogNotes as T)]);
      } else {
        if ([PtDashboardFilters.UnreadMessage, PtDashboardFilters.UnreadMessageNoResponse].includes(selectedPtDashboardFilter.filterLabel)) {
          if (selectedPtDashboardFilter.filterTabName === NO_RESPONSE_PREDICTED_TAB_NAME) {
            setTotalCount(counts[(ptLabel as T)]);
            let workflowCountToSet;
            bb101: switch (selectedUnreadFilter) {
              case "HLN":
                {
                  workflowCountToSet = totalmodularIAMLowPriority;
                  break bb101;
                }
              case "Messages":
                {
                  workflowCountToSet = totalUnreadLowPriority;
                  break bb101;
                }
              case "All Types":
                {
                  workflowCountToSet = totalAllTypesLowPriority;
                  break bb101;
                }
              default:
                {
                  workflowCountToSet = counts[(ptLabel as T)];
                }
            }
            return setWorkflowCounts(workflowCountToSet);
          } else {
            setTotalCount(counts[(ptLabel as T)]);
            let workflowCountToSet_0;
            bb112: switch (selectedUnreadFilter) {
              case "HLN":
                {
                  workflowCountToSet_0 = iamMessageCountTotal;
                  break bb112;
                }
              case "Messages":
                {
                  workflowCountToSet_0 = messageCountTotal;
                  break bb112;
                }
              case "All Types":
                {
                  workflowCountToSet_0 = allTypesCountTotal;
                  break bb112;
                }
              default:
                {
                  workflowCountToSet_0 = counts[(ptLabel as T)];
                }
            }
            return setWorkflowCounts(workflowCountToSet_0);
          }
        } else {
          if (selectedPtDashboardFilter.filterTabName !== DEFAULT_COACH_DASHBOARD_TAB_NAME) {
            setWorkflowCounts(counts[(selectedPtDashboardFilter.filterLabel as T)]);
            setTotalCount(counts[(selectedPtDashboardFilter.filterLabel as T)]);
          } else {
            setWorkflowCounts(counts[(ptLabel as T)]);
            setTotalCount(counts[(ptLabel as T)]);
          }
        }
      }
    }
  }, [dashboardType, counts, ptLabel, selectedPtDashboardFilter.filterLabel, selectedPtDashboardFilter.filterTabName, setTotalCount, workflows, selectedUnreadFilter, iamMessageCountTotal, messageCountTotal, allTypesCountTotal, totalmodularIAMLowPriority, totalUnreadLowPriority, totalAllTypesLowPriority]);
  useEffect(() => {
    if (dashboardType === DashboardType.OnboardingSpecialist) {
      if ([OsDashboardFilters.UnreadMessage, OsDashboardFilters.UnreadMessageNoResponse].includes(selectedOsDashboardFilter.filterLabel)) {
        if (selectedOsDashboardFilter.filterTabName === OS_NO_RESPONSE_PREDICTED_TAB_NAME) {
          setTotalCount(counts[(OsDashboardFilters.UnreadMessageNoResponse as T)]);
          return setWorkflowCounts(counts[(OsDashboardFilters.UnreadMessageNoResponse as T)]);
        }
        return setWorkflowCounts(counts[(OsDashboardFilters.UnreadMessage as T)]);
      }
      return setWorkflowCounts(counts[(selectedOsDashboardFilter.filterLabel as T)]);
    }
  }, [dashboardType, counts, selectedOsDashboardFilter.filterLabel, selectedOsDashboardFilter.filterTabName, setTotalCount, workflows]);
  useBulkProgressTimeout(bulkHLNProgress, lowPriorityNotesSplitFlag, setShouldRefetchWorkflows, setBulkHLNProgress);
  useBulkProgressTimeout(bulkUnreadProgress, lowPriorityNotesSplitFlag, setShouldRefetchWorkflows, setUnreadProgress);
  let messageSnackbar;
  messageSnackbar = TASKS_LOAD_ERROR;
  if (selectedCoachDashboardFilter.filterLabel === CoachDashboardFilters.MemberHealthLog) {
    messageSnackbar = HEALTH_LOG_NOTES_LOAD_ERROR;
  }
  if (selectedCoachDashboardFilter.filterLabel === CoachDashboardFilters.UnreadMessages) {
    messageSnackbar = UNREAD_MESSAGES_LOAD_ERROR;
  }
  if (bulkHLNProgress && (healthLogNotesSplitFlag || ptHealthLogNotesDualTabFlag) || bulkUnreadProgress && (unreadMessagesDualTabTreatment || ptUnreadMessagesDualTabFlag)) {
    messageSnackbar = TASKS_BEING_UPDATED;
  }
  const handleOnPageChange = (_event, newPage) => {
    let setFilter;
    if (dashboardType === DashboardType.Coach) {
      setFilter = setSelectedCoachDashboardFilter;
    } else {
      if (dashboardType === DashboardType.PhysicalTherapist) {
        setFilter = setSelectedPtDashboardFilter;
      } else {
        setFilter = setSelectedOsDashboardFilter;
      }
    }
    setFilter(prevFilter => ({
      ...prevFilter,
      filterPageNumber: newPage
    }));
  };
  let pageNumber;
  if (dashboardType === DashboardType.Coach) {
    pageNumber = selectedCoachDashboardFilter.filterPageNumber;
  } else {
    if (dashboardType === DashboardType.PhysicalTherapist) {
      pageNumber = selectedPtDashboardFilter.filterPageNumber;
    } else {
      pageNumber = selectedOsDashboardFilter.filterPageNumber;
    }
  }
  const t8 = messageSnackbar;
  const t9 = pageNumber;
  let t10;
  if ($[13] !== error || $[14] !== dashboardType || $[15] !== t8 || $[16] !== openSnackbar || $[17] !== workflowListLoadingState || $[18] !== selectedCoachDashboardFilter.filterLabel || $[19] !== selectedPtDashboardFilter.filterLabel || $[20] !== selectedOsDashboardFilter.filterLabel || $[21] !== workflows || $[22] !== showPagination || $[23] !== workflowCounts || $[24] !== t9 || $[25] !== handleOnPageChange) {
    t10 = () => {
      if (error) {
        return <React.Fragment><WorkflowsTableError /><HHSnackbarAlert severity="error" message={dashboardType === DashboardType.Coach ? messageSnackbar : TASKS_LOAD_ERROR} verticalAlignment="top" horizontalAlignment="right" hhVariant="standard" open={openSnackbar} onClose={handleSnackbar} /></React.Fragment>;
      } else {
        if (workflowListLoadingState) {
          return <HHBox sx={workflowsTableStyles.spinnerStyle}><HHCircularProgress data-testid="hh-circular-progress" /></HHBox>;
        } else {
          return <HHTableContainer><HHTable sx={[CoachDashboardFilters.MemberHealthLog, CoachDashboardFilters.UnreadMessages].includes(selectedCoachDashboardFilter.filterLabel) || [PtDashboardFilters.HealthLogNotes, PtDashboardFilters.UnreadMessage].includes(selectedPtDashboardFilter.filterLabel) || [OsDashboardFilters.UnreadMessage].includes(selectedOsDashboardFilter.filterLabel) ? lowPriorityStyles : {}}><WorkflowsTableContainer workflows={workflows ?? []} />{showPagination && <WorkflowsPagination workflowCounts={workflowCounts} pageNumber={pageNumber} onPageChange={handleOnPageChange} />}</HHTable></HHTableContainer>;
        }
      }
    };
    $[13] = error;
    $[14] = dashboardType;
    $[15] = t8;
    $[16] = openSnackbar;
    $[17] = workflowListLoadingState;
    $[18] = selectedCoachDashboardFilter.filterLabel;
    $[19] = selectedPtDashboardFilter.filterLabel;
    $[20] = selectedOsDashboardFilter.filterLabel;
    $[21] = workflows;
    $[22] = showPagination;
    $[23] = workflowCounts;
    $[24] = t9;
    $[25] = handleOnPageChange;
    $[26] = t10;
  } else {
    t10 = $[26];
  }
  const WorkflowsTableBody = t10;
  let t11;
  if ($[27] !== dashboardType || $[28] !== selectedCoachDashboardFilter.filterLabel || $[29] !== WorkflowsTableBody || $[30] !== bulkHLNProgress || $[31] !== showTimeOutErr || $[32] !== unreadMessagesDualTabTreatment || $[33] !== bulkUnreadProgress || $[34] !== selectedPtDashboardFilter.filterLabel || $[35] !== ptLabel || $[36] !== ptHealthLogNotesDualTabFlag || $[37] !== ptUnreadMessagesDualTabFlag || $[38] !== selectedOsDashboardFilter.filterLabel || $[39] !== osLabel) {
    t11 = () => {
      if (dashboardType === DashboardType.Coach) {
        if (selectedCoachDashboardFilter.filterLabel === CoachDashboardFilters.MemberHealthLog) {
          return <HHBox><HealthLogNotesTabs tableContent={<WorkflowsTableBody />} />{bulkHLNProgress && !showTimeOutErr && <TaskUpdating />}</HHBox>;
        }
        if (selectedCoachDashboardFilter.filterLabel === CoachDashboardFilters.EnsoWeek1) {
          return <HHBox sx={workflowsTableStyles.bodyContainer}><EnsoWeek1Tabs tableContent={<WorkflowsTableBody />} /></HHBox>;
        }
        if (coachCallFilters.includes(selectedCoachDashboardFilter.filterLabel)) {
          return <HHBox><CoachCallsTabs tableContent={<WorkflowsTableBody />} /></HHBox>;
        }
        if (selectedCoachDashboardFilter.filterLabel === CoachDashboardFilters.UnreadMessages && unreadMessagesDualTabTreatment) {
          return <HHBox><UnreadMessagesTabs tableContent={<WorkflowsTableBody />} />{bulkUnreadProgress && !showTimeOutErr && <TaskUpdating />}</HHBox>;
        }
      }
      if (dashboardType === DashboardType.PhysicalTherapist) {
        if (videoVisitFilters.includes(selectedPtDashboardFilter.filterLabel)) {
          return <><HHBox sx={workflowsTableStyles.titleContainer}><HHTypography hhVariant="variant-bypass" sx={workflowsTableStyles.titleTypography}>{ptLabel}</HHTypography></HHBox><HHBox sx={workflowsTableStyles.bodyContainerTabs}><HHDivider /><VideoVisitTabs tableContent={<WorkflowsTableBody />} /></HHBox></>;
        }
        if (houseCallFilters.includes(selectedPtDashboardFilter.filterLabel)) {
          const tabsProps = getHouseCallTabsProps(<WorkflowsTableBody />);
          return <><HHBox sx={workflowsTableStyles.titleContainer}><HHTypography hhVariant="variant-bypass" sx={workflowsTableStyles.titleTypography}>{ptLabel}</HHTypography></HHBox><HHBox sx={workflowsTableStyles.bodyContainer}><HHDivider /><WorkflowTableTabs {...tabsProps} /></HHBox></>;
        }
        if (healthLogFilters.includes(selectedPtDashboardFilter.filterLabel) && ptHealthLogNotesDualTabFlag) {
          return <><HHBox sx={workflowsTableStyles.bodyContainerTabs}><PtHealthLogNotesTabs tableContent={<WorkflowsTableBody />} />{bulkHLNProgress && !showTimeOutErr && <TaskUpdating />}</HHBox></>;
        }
        if (unreadMessageFilters.includes(selectedPtDashboardFilter.filterLabel) && ptUnreadMessagesDualTabFlag) {
          return <><HHBox sx={workflowsTableStyles.bodyContainerTabs}><PtUnreadMessagesTabs tableContent={<WorkflowsTableBody />} />{bulkUnreadProgress && !showTimeOutErr && <TaskUpdating />}</HHBox></>;
        }
      }
      if (dashboardType === DashboardType.OnboardingSpecialist) {
        if (selectedOsDashboardFilter.filterLabel === OsDashboardFilters.UnreadMessage && unreadMessagesDualTabTreatment) {
          return <HHBox><OSUnreadMessagesTabs tableContent={<WorkflowsTableBody />} />{bulkUnreadProgress && !showTimeOutErr && <TaskUpdating />}</HHBox>;
        }
      }
      let label;
      if (dashboardType === DashboardType.Coach) {
        label = selectedCoachDashboardFilter.filterLabel === CoachDashboardFilters.All ? COACH_WORKFLOWS_TABLE.SECTION_TITLE : selectedCoachDashboardFilter.filterLabel;
      } else {
        if (dashboardType === DashboardType.PhysicalTherapist) {
          label = ptLabel;
        } else {
          label = osLabel;
        }
      }
      return <><HHBox sx={workflowsTableStyles.titleContainer}><HHTypography hhVariant="variant-bypass" sx={workflowsTableStyles.titleTypography}>{label}</HHTypography></HHBox><HHBox sx={workflowsTableStyles.bodyContainer}><HHDivider /><WorkflowsTableBody /></HHBox></>;
    };
    $[27] = dashboardType;
    $[28] = selectedCoachDashboardFilter.filterLabel;
    $[29] = WorkflowsTableBody;
    $[30] = bulkHLNProgress;
    $[31] = showTimeOutErr;
    $[32] = unreadMessagesDualTabTreatment;
    $[33] = bulkUnreadProgress;
    $[34] = selectedPtDashboardFilter.filterLabel;
    $[35] = ptLabel;
    $[36] = ptHealthLogNotesDualTabFlag;
    $[37] = ptUnreadMessagesDualTabFlag;
    $[38] = selectedOsDashboardFilter.filterLabel;
    $[39] = osLabel;
    $[40] = t11;
  } else {
    t11 = $[40];
  }
  const generateWorkflowBody = t11;
  let t12;
  if ($[41] !== generateWorkflowBody) {
    t12 = generateWorkflowBody();
    $[41] = generateWorkflowBody;
    $[42] = t12;
  } else {
    t12 = $[42];
  }
  let t13;
  if ($[43] !== t12) {
    t13 = <HHBox data-testid={COACH_LAYOUT.WORKFLOWS_TABLE_CONTAINER_ID} sx={workflowsTableStyles.wrapper}>{t12}</HHBox>;
    $[43] = t12;
    $[44] = t13;
  } else {
    t13 = $[44];
  }
  return t13;
};