import { ApolloError } from '@apollo/client';
import { ChartSeriesWithMultipleLegend, HHBaseTableBodyContent, HHBaseTableHeaderCell } from '@hinge-health/react-component-library';
import { DateTime } from 'luxon';
import { CreateMessageFeedbackResponse, PainScoreVisualisation } from '../../types';
import { SseEvents } from './constants/events';
export enum CoachTransitionMapState {
  CoachComplete = 'complete',
  CoachDismiss = 'dismiss',
  CoachNew = 'new',
  CoachOpen = 'open',
  CoachPending = 'pending',
  CoachReady = 'ready',
  NewHealthLogNote = 'new-health-log-note',
  NewPainUptick = 'new-pain-uptick',
  NewCoachEscalation = 'new-coach-escalation',
  NewWeeklyEnsoReview = 'new',
  ActionPlanInLastWeek = 'action-plan-in-last-week',
  CoachClosed = 'closed',
  ActionPlanOverdue = 'action-plan-overdue',
  MemberCreatedWeeklyGoal = 'member-created-weekly-goal',
  MemberUpdatedWeeklyGoal = 'member-updated-weekly-goal',
}
export enum PtTransitionMapState {
  AcuteComplete = 'acute-complete',
  PainUptick = 'pain-uptick',
  NewEtModification = 'new-et-modification',
  NewMedicalEmergencyFollowUp = 'new-medical-emergency-follow-up',
  NewClinicalEscalation = 'new-clinical-escalation',
  NewFirstWeekEnsoCheckIn = 'new',
  CarePlanToClose = 'care-plan-needs-close',
  ExpiringCarePlanOpen = 'expiring-full',
  ExpiringRestrictedCarePlanOpen = 'expiring-restricted',
  AcuteDismiss = 'acute-dismiss',
}
export enum HighRiskMemberTransitionMapState {
  EnsoPresumedDelivered = 'enso-presumed-delivered',
  OutreachComplete = 'outreach-complete',
}
export interface CurrentAdmin {
  uuid: string;
  email?: string;
  firstName: string;
  lastName: string;
  role?: string;
}
export enum CommonCoachState {
  CoachComplete = 'complete',
  CoachDismiss = 'dismiss',
  CoachAwaitingNew = 'awaiting-new',
  CoachAwaitingPlan = 'awaiting-plan',
  CoachArchive = 'archive',
  CoachClosed = 'closed',
  CoachOutreachSentToMember = 'outreach-sent-to-member',
  CoachDismissed = 'dismissed',
  CoachPending = 'pending',
  CoachVideoVisitAlreadyScheduledOrComplete = 'video-visit-already-scheduled-or-complete',
  Active = 'active',
  Closed = 'closed',
  CoachUserTransitionedToNewProgram = 'user-transitioned-to-new-program',
}
export enum CoachWorkflowTypes {
  FirstActionPlan = 'first-action-plan',
  UnreadMessages = 'unread-messages',
  ReEngager = 'reengager',
  MemberAlertReminder = 'member-alert-reminder',
  ActionPlanReview = 'action-plan-review',
  FirstWeekGoal = 'first-week-goal',
  PregnancyStatusChange = 'pregnancy-status-change',
  OfferVideoVisit = 'offer-video-visit',
  MemberHealthLog = 'member-health-log',
  MemberPainUptick = 'member-pain-uptick',
  MaternityMilestones = 'maternity-milestones',
  FirstWeekCheckIn = 'first-week-check-in',
  ActionPlanCheckIn = 'action-plan-check-in',
  EnsoWeek1 = 'enso-week-1',
  CoachCall = 'coach-call',
  BalanceAssessment = 'balance-assessment',
  CoachEscalation = 'coach-escalation',
}
export interface CoachSseData {
  type: SseEvents;
  workflowId: number;
  serviceName: CoachWorkflowTypes;
  stateName: CommonCoachState;
}
export enum BalanceAverageCategories {
  BelowAverage = 'low',
  Average = 'average',
  AboveAverage = 'high',
}
export enum BalanceAssessmentTypes {
  SitToStand = 'sitToStand',
  FiveStageTest = 'fiveStageTest',
}
export interface BalanceAssessmentData {
  sitToStand?: {
    score: number;
    category: BalanceAverageCategories;
    date: string;
  };
  fiveStageTest?: {
    score: number;
    date: string;
  };
}
export interface BalanceCustomFields {
  currentAssessment: BalanceAssessmentData;
  previousAssessment?: null | BalanceAssessmentData;
}
export type FormattedCoverageDates = {
  previousCoverage: string;
  activeCoverage: string;
};
export type MessageFeedbackResponse = {
  loading?: boolean;
  error?: ApolloError | string[];
  data?: CreateMessageFeedbackResponse | undefined;
};
export enum PathwayType {
  Chronic = 'chronic',
  Acute = 'acute',
  UnifiedV1 = 'unified_v1',
}

/* Pain score chart types */
interface PainData {
  forDate: string;
  painScore: number;
  priority: number;
}
export interface DailyEntry {
  indication: string;
  painData: PainData[];
  priority: number;
  baseline?: {
    from: number;
    to: number;
    color: string;
    name: string;
  };
}
export interface WeeklyEntry {
  painData: {
    indication: string;
    painScore: number;
    priority: number;
  }[];
  endDate: string;
  startDate: string;
}
export interface PainDataResponse {
  daily: DailyEntry[];
  weekly: WeeklyEntry[];
}
export type ChartDataModes = 'daily' | 'weekly';
export interface PreparePainScoreDataProps {
  mode: ChartDataModes;
  data: PainScoreVisualisation;
  startDate?: string;
  endDate?: string;
  indications: string[];
}
export type PainScoreChartUpticks = {
  value: number | null;
  note?: string;
};
export type DefaultChartValuesWithDate = {
  value: number | null;
  date: DateTime;
};
export type ChartSeriesItem = {
  name: string;
  color?: string;
  data: PainScoreChartUpticks[];
  group?: string;
};
export type PainScoreChartSeriesDataWithDates = {
  name: string;
  color?: string;
  data: DefaultChartValuesWithDate[];
};
export type PreparePainScoreResult = {
  list: ChartLabelListItem[];
  series: ChartSeriesItem[];
};
export type PrepareExerciseTherapyCountResult = {
  list: GenerateCategoryAxisListResult;
  series: ChartSeriesWithMultipleLegend[];
};
export type PainScoreDataPartial = Partial<PainScoreVisualisation> & Partial<PainDataResponse>;
export type CalculateDateRangeResult = {
  start: string;
  end: string;
};
export type MapChartData = {
  startDateObj: DateTime;
  endDateObj: DateTime;
  data: PainDataResponse;
  indications: string[];
};
export type ChartLabelListItem = string | Date;
export type UpdateListAndSeriesWithDefaultData = {
  mode: ChartDataModes;
  dateRange: {
    startDateObj: DateTime;
    endDateObj: DateTime;
  };
  list: ChartLabelListItem[];
  defaultChartValuesWithDates: DefaultChartValuesWithDate[];
};
export type DefaultChartDateRange = [Date, Date];
export type ChartDatePickerDateRange = [Date | null, Date | null];
export type ExerciseTherapySessionState = {
  etCompletedCount: number[];
  etSkippedCount: number[];
  etUnattemptedCount: number[];
};
export type ExerciseTherapySessionWeeklyState = {
  etCompletedCount: number;
  etSkippedCount: number;
  etUnattemptedCount: number;
};
export interface GetExerciseTherapySessionsForVisualizationData {
  userUuid: string;
  dailyEtSessions: {
    dateRange: {
      endDate: string;
      startDate: string;
    };
    ftu: ExerciseTherapySessionState;
    library: ExerciseTherapySessionState;
    playlist: ExerciseTherapySessionState;
    dmet: ExerciseTherapySessionState;
  };
  weeklyEtSessions: {
    weekNumber: number;
    dateRange: {
      startDate: string;
      endDate: string;
    };
    ftu: ExerciseTherapySessionWeeklyState;
    library: ExerciseTherapySessionWeeklyState;
    playlist: ExerciseTherapySessionWeeklyState;
    dmet: ExerciseTherapySessionWeeklyState;
  }[];
}
export type KeysOfDailyEtSessions = Exclude<keyof GetExerciseTherapySessionsForVisualizationData['dailyEtSessions'], 'dateRange'>;
export type KeysOfWeeklyEtSessions = Exclude<keyof GetExerciseTherapySessionsForVisualizationData['weeklyEtSessions'][0], 'dateRange' | 'weekNumber'>;
export type QueryDateRange = {
  start: string;
  end: string;
};
export type GenerateExerciseTherapySeriesResult = {
  name: string;
  color: string;
  group: string;
  data: number[] | number[][];
}[];
export type GenerateCategoryAxisListResult = string[] | Date[];
export interface GetExerciseTherapyGoalsForVisualisationData {
  dailyEtGoals: number[];
  weeklyEtGoals: {
    endDate: string;
    etCompleted: number;
    startDate: string;
    targetGoal: number;
  }[];
}
export type GetExerciseTherapyGoalsForVisualisationDataResponse = {
  getExerciseTherapyGoals: GetExerciseTherapyGoalsForVisualisationData;
};
export interface GetEnsoStatsData {
  sessions: {
    daily: {
      numberOfSessions: number[];
      sessionHours: number[];
    };
    weekly: {
      from: string;
      numberOfSessions: number;
      sessionHours: number;
      to: string;
    }[];
  };
  stats: MemberEnsoStats[];
}
export type GetEnsoStatsQueryResponse = {
  getEnsoStats: GetEnsoStatsData;
};
export interface Series {
  name: string;
  color: string;
  group: string;
  data: number[] | number[][];
}
export interface MemberEnsoStats {
  painLocation: string;
  sessionHours: number;
  numberOfSessions: number;
  peakIntensity: number;
  averageIntensity: number;
}
export interface ProcessStatsResult {
  bodyContent: HHBaseTableBodyContent[];
  tableHeaders: HHBaseTableHeaderCell[];
}