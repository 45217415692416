import { c as _c } from "react-compiler-runtime";
import { HHBox, HHTrend, HHTrendProps, HHTypography } from '@hinge-health/react-component-library';
import { basiliskSplitClient } from '../../../../../config';
import { ON } from '../../../constants/splits';
import { MultiIndicationPainScores } from '../../../types';
const PAIN_THRESHOLD = 20;
const HIGH_PAIN_THRESHOLD = 29;
export const getPainScoreTrendProps = (change: number, isHighPainSplitFlagActive: boolean): HHTrendProps => {
  const painThreshold = isHighPainSplitFlagActive ? HIGH_PAIN_THRESHOLD : PAIN_THRESHOLD;
  if (change > 0 && change < painThreshold) {
    return {
      value: change,
      hhVariant: 'info'
    };
  } else if (change >= painThreshold) {
    return {
      value: change,
      hhVariant: 'error'
    };
  } else {
    return {
      value: change,
      hhVariant: 'success'
    };
  }
};
export interface PainScoreChangeProps {
  painDetails: MultiIndicationPainScores;
}
const styles = {
  contentContainer: {
    alignItems: 'center',
    display: 'flex'
  }
};
export const PainScoreChange = props => {
  const $ = _c(9);
  const {
    score,
    change,
    hideTrend
  } = props.painDetails;
  const isHighPainSplitFlagActive = basiliskSplitClient?.getTreatment("high_pain_threshold") === ON;
  let t0;
  if ($[0] !== hideTrend || $[1] !== change) {
    t0 = !hideTrend && <HHTrend showArrowOnly={true} {...getPainScoreTrendProps(change ?? 0, isHighPainSplitFlagActive)} />;
    $[0] = hideTrend;
    $[1] = change;
    $[2] = t0;
  } else {
    t0 = $[2];
  }
  let t1;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = theme => theme.palette.error.dark;
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  const t2 = hideTrend ? score : change;
  let t3;
  if ($[4] !== t2) {
    t3 = <HHTypography hhVariant="body1" color={t1}>{t2}</HHTypography>;
    $[4] = t2;
    $[5] = t3;
  } else {
    t3 = $[5];
  }
  let t4;
  if ($[6] !== t0 || $[7] !== t3) {
    t4 = <HHBox sx={styles.contentContainer}>{t0}{t3}</HHBox>;
    $[6] = t0;
    $[7] = t3;
    $[8] = t4;
  } else {
    t4 = $[8];
  }
  return t4;
};