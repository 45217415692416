import { c as _c } from "react-compiler-runtime";
import { HHTabData, HHTabs } from '@hinge-health/react-component-library';
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { CoachDashboardFilter } from '../../../../dashboard/constants/coach-dashboard';
import { ENSO_WEEK_1_ENCOUNTERS, ENSO_WEEK_1_WORKFLOWS } from '../../../../dashboard/constants/strings/coach-dashboard-default';
import { selectedCoachDashboardFilters } from '../../../jotai/atoms';
import { CoachDashboardFilters } from '../../../types/coach';
export interface EnsoWeek1TabsProps {
  tableContent: JSX.Element;
}
export const EnsoWeek1Tabs: React.FC<EnsoWeek1TabsProps> = t0 => {
  const $ = _c(16);
  const {
    tableContent
  } = t0;
  const [, setSelectedDashboardFilter] = useAtom(selectedCoachDashboardFilters);
  let t1;
  let t2;
  if ($[0] !== setSelectedDashboardFilter) {
    t1 = () => {
      setSelectedDashboardFilter(CoachDashboardFilter[CoachDashboardFilters.EnsoWeek1]);
    };
    t2 = [setSelectedDashboardFilter];
    $[0] = setSelectedDashboardFilter;
    $[1] = t1;
    $[2] = t2;
  } else {
    t1 = $[1];
    t2 = $[2];
  }
  useEffect(t1, t2);
  let t3;
  if ($[3] !== setSelectedDashboardFilter) {
    t3 = event => {
      if (event == 0) {
        setSelectedDashboardFilter(CoachDashboardFilter[CoachDashboardFilters.EnsoWeek1]);
      }
    };
    $[3] = setSelectedDashboardFilter;
    $[4] = t3;
  } else {
    t3 = $[4];
  }
  const handleTabChange = t3;
  let t4;
  if ($[5] !== tableContent) {
    t4 = {
      tabContent: tableContent,
      tabLabel: ENSO_WEEK_1_WORKFLOWS,
      key: ENSO_WEEK_1_WORKFLOWS
    };
    $[5] = tableContent;
    $[6] = t4;
  } else {
    t4 = $[6];
  }
  let t5;
  if ($[7] !== tableContent) {
    t5 = {
      tabContent: tableContent,
      tabLabel: ENSO_WEEK_1_ENCOUNTERS,
      key: ENSO_WEEK_1_ENCOUNTERS
    };
    $[7] = tableContent;
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  let t6;
  if ($[9] !== t4 || $[10] !== t5) {
    t6 = [t4, t5];
    $[9] = t4;
    $[10] = t5;
    $[11] = t6;
  } else {
    t6 = $[11];
  }
  const tabsDataContent = t6;
  let t7;
  if ($[12] === Symbol.for("react.memo_cache_sentinel")) {
    t7 = {
      margin: "2px"
    };
    $[12] = t7;
  } else {
    t7 = $[12];
  }
  let t8;
  if ($[13] !== tabsDataContent || $[14] !== handleTabChange) {
    t8 = <HHTabs layoutStyles={t7} tabsData={tabsDataContent} onTabChange={handleTabChange} />;
    $[13] = tabsDataContent;
    $[14] = handleTabChange;
    $[15] = t8;
  } else {
    t8 = $[15];
  }
  return t8;
};