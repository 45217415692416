import { c as _c } from "react-compiler-runtime";
import { HHBox, HHTableCell, HHTypography } from '@hinge-health/react-component-library';
import { IamMessageSource } from '../../../../../../custom-types';
import { HLN_UNREAD, MESSAGE } from '../../../../../member-360/modules/panel-layouts/constants/panel';
import { WorkflowTypeCustomFieldsActionPlansFragment } from '../../../../types';
import { styles } from './style';
interface WorkflowTypeCellProps {
  workflow: WorkflowTypeCustomFieldsActionPlansFragment;
}
const WorkflowUnreadTypeCell = t0 => {
  const $ = _c(7);
  const {
    workflow
  } = t0;
  let t1;
  if ($[0] !== workflow) {
    t1 = () => {
      if (workflow?.customFields?.source === IamMessageSource.HLN) {
        return HLN_UNREAD;
      }
      return MESSAGE;
    };
    $[0] = workflow;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  const getWorkflowTypeLabel = t1;
  let t2;
  if ($[2] !== getWorkflowTypeLabel) {
    t2 = getWorkflowTypeLabel();
    $[2] = getWorkflowTypeLabel;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  const workflowTypeLabel = t2;
  let t3;
  if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = {
      display: "flex",
      flexDirection: "column"
    };
    $[4] = t3;
  } else {
    t3 = $[4];
  }
  let t4;
  if ($[5] !== workflowTypeLabel) {
    t4 = <HHTableCell sx={styles.cell}><HHBox sx={t3} data-testid="workflow-unread-type-cell"><HHTypography hhVariant="body">{workflowTypeLabel}</HHTypography></HHBox></HHTableCell>;
    $[5] = workflowTypeLabel;
    $[6] = t4;
  } else {
    t4 = $[6];
  }
  return t4;
};
export default WorkflowUnreadTypeCell;