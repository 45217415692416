import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
export const GENERATED_KEY = 'GeneratedKey';
export const GENERATED_INDEX = 'GeneratedIndex';
export const generateKey = (key?: string, index?: string | number): string => {
  const randomIndex = uuidv4();
  // Typecasting numbers to string
  index = index?.toString();
  key = key && key.length > 0 ? key : GENERATED_KEY;
  index = index && index.length > 0 ? index : GENERATED_INDEX;
  return `${key}-${index}-${randomIndex}`;
};
interface CallData {
  firstCallTime: string;
  callCount: number;
}
const PARENT_KEY = 'rateLimited';
export const reloadApp = (): void => {
  window.location.reload();
};
const getRateLimitData = (): Record<string, CallData> => JSON.parse(localStorage.getItem(PARENT_KEY) || '{}');
const setRateLimitData = (data: Record<string, CallData>): void => {
  localStorage.setItem(PARENT_KEY, JSON.stringify(data));
};
interface TriggerAlertAfterRateLimitProps {
  functionName: string;
  thresholdMinutes?: number;
  maxCalls?: number;
  message?: string;
  callback?: () => void;
  meta?: Record<string, unknown>;
}
export const triggerAlertAfterRatelimit = (props: TriggerAlertAfterRateLimitProps): boolean => {
  const {
    functionName,
    thresholdMinutes = 1,
    maxCalls = 20,
    message = `${functionName}'s function call rate limit of ${maxCalls} exceeded in ${thresholdMinutes} minutes`,
    callback,
    meta
  } = props;
  const now = DateTime.now();
  const rateLimitData = getRateLimitData();
  const callData = rateLimitData[functionName];
  if (!callData) {
    rateLimitData[functionName] = {
      firstCallTime: now.toISO(),
      callCount: 1
    };
    setRateLimitData(rateLimitData);
    return true;
  }
  const firstCallDateTime = DateTime.fromISO(callData.firstCallTime);
  const timeSinceFirstCall = now.diff(firstCallDateTime, 'minutes').minutes;
  if (timeSinceFirstCall < thresholdMinutes) {
    if (callData.callCount >= maxCalls) {
      const error = new Error(message);
      Sentry.captureException(error, {
        ...meta,
        level: 'warning'
      });
      delete rateLimitData[functionName];
      setRateLimitData(rateLimitData);
      if (callback) callback();
      return false;
    } else {
      rateLimitData[functionName].callCount += 1;
      setRateLimitData(rateLimitData);
      return true;
    }
  } else {
    rateLimitData[functionName] = {
      firstCallTime: now.toISO(),
      callCount: 1
    };
    setRateLimitData(rateLimitData);
    return true;
  }
};