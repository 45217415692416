import { HHInfoGridProps, PriorityStates } from '@hinge-health/react-component-library';
import { ActionPlan, WorkflowPayload } from './types';
export enum CoachWorkflowTypes {
  FirstActionPlan = 'first-action-plan',
  UnreadMessages = 'unread-messages',
  MemberAlertReminder = 'member-alert-reminder',
  ActionPlanReview = 'action-plan-review',
  FirstWeekGoal = 'first-week-goal',
  PregnancyStatusChange = 'pregnancy-status-change',
  OfferVideoVisit = 'offer-video-visit',
  MemberHealthLog = 'member-health-log',
  MemberPainUptick = 'member-pain-uptick',
  MaternityMilestones = 'maternity-milestones',
  ActionPlanCheckIn = 'action-plan-check-in',
  FirstWeekCheckIn = 'first-week-check-in',
  EnsoWeek1 = 'enso-week-1',
  ReEngager = 'reengager',
  CoachCall = 'coach-call',
  CoachEscalation = 'coach-escalation',
  WeeklyEnsoReview = 'weekly-enso-review',
  OutreachNeeded = 'outreach-needed',
  CoachWeeklyGoalExpiry = 'coach-weekly-goal-expiry',
  BalanceAssessment = 'balance-assessment',
  CoachMemberUpdatedWeeklyGoal = 'coach-member-updated-weekly-goal',
}
export const healthLogsWorkflows = [CoachWorkflowTypes.MemberHealthLog, CoachWorkflowTypes.MemberPainUptick];
export const coachQueueWorkflowTypes = [CoachWorkflowTypes.FirstActionPlan, CoachWorkflowTypes.UnreadMessages, CoachWorkflowTypes.ActionPlanReview, CoachWorkflowTypes.FirstWeekGoal, CoachWorkflowTypes.MemberHealthLog, CoachWorkflowTypes.MemberPainUptick, CoachWorkflowTypes.CoachCall];
export enum CoachWorkflowState {
  AwaitingNew = 'awaiting-new',
  AwaitingPlan = 'awaiting-plan',
  NoPlan = 'no-plan',
  NewPlan = 'new-plan',
  New = 'new',
  PendingCreation = 'action-plan-pending-creation',
  PendingCreationImmediate = 'action-plan-pending-creation-immediate',
  PendingReview = 'action-plan-pending-review',
  AdminCreatedPlan = 'admin-created-action-plan',
  MemberUpdatedPlan = 'member-updated-action-plan',
  PregnancyStatusChange = 'pregnancy-status-change',
  MaternityMilestones = 'maternity-milestones',
  NeedsVideoVisitOffer = 'needs-video-visit-offer',
  NeedsResponse = 'needs-response',
  OutreachSentToMember = 'outreach-sent-to-member',
  Dismissed = 'dismissed',
  Pending = 'pending',
  Complete = 'complete',
  VideoVisitAlreadyScheduledOrComplete = 'video-visit-already-scheduled-or-complete',
  Active = 'active',
}
export interface MemberDetails {
  memberName: string;
  memberId: string;
  memberUuid: string;
  email: string;
  timezone: string;
  teamId?: string;
}
export interface GridInfo {
  primaryAdmin: HHInfoGridProps['assignee'];
  baseMemberDetails: MemberDetails;
  loading: HHInfoGridProps['loading'];
  mostRecentActionPlan?: ActionPlan;
  createdAt?: string;
  workflowType?: string;
}
export enum CommonCoachState {
  CoachComplete = 'complete',
  CoachDismiss = 'dismiss',
  CoachAwaitingNew = 'awaiting-new',
  CoachAwaitingPlan = 'awaiting-plan',
  CoachArchive = 'archive',
  CoachClosed = 'closed',
  CoachOutreachSentToMember = 'outreach-sent-to-member',
  CoachDismissed = 'dismissed',
  CoachPending = 'pending',
  CoachVideoVisitAlreadyScheduledOrComplete = 'video-visit-already-scheduled-or-complete',
  Active = 'active',
  Closed = 'closed',
  CoachUserTransitionedToNewProgram = 'user-transitioned-to-new-program',
  AwaitingMessage = 'awaiting-message',
  AwaitingTag = 'awaiting-tag',
  CancelVideoVisit = 'cancel-video-visit',
  PostFwg = 'post-fwg',
  EnsoAssignedToWorkflow = 'assigned-to-workflow',
  EnsoOrdered = 'enso-ordered',
  AcuteComplete = 'acute-complete',
}
export type CoachDecoratedWorkflow = WorkflowPayload & {
  dueState?: PriorityStates;
};
export enum CoachGroupTypes {
  Title = 'title',
}
export enum IframeSourceName {
  MesssagingTeam = 'messaging-team',
  MemberProfile = 'member-profile',
  MemberAlerts = 'memberAlerts',
  Engagement = 'engagement',
}
export const commonExcludedStates: CommonCoachState[] = [CommonCoachState.CoachComplete, CommonCoachState.CoachDismiss, CommonCoachState.CoachAwaitingNew, CommonCoachState.CoachAwaitingPlan, CommonCoachState.CoachArchive, CommonCoachState.CoachClosed, CommonCoachState.CoachOutreachSentToMember, CommonCoachState.CoachDismissed, CommonCoachState.CoachUserTransitionedToNewProgram, CommonCoachState.CoachPending, CommonCoachState.CoachVideoVisitAlreadyScheduledOrComplete, CommonCoachState.AwaitingMessage, CommonCoachState.AwaitingTag, CommonCoachState.PostFwg, CommonCoachState.CancelVideoVisit, CommonCoachState.EnsoAssignedToWorkflow, CommonCoachState.EnsoOrdered, CommonCoachState.AcuteComplete];
export const commonCompletedStates: CommonCoachState[] = [CommonCoachState.CoachComplete, CommonCoachState.CoachDismiss, CommonCoachState.CoachAwaitingPlan, CommonCoachState.CoachArchive, CommonCoachState.CoachClosed, CommonCoachState.CoachOutreachSentToMember, CommonCoachState.CoachDismissed, CommonCoachState.CoachUserTransitionedToNewProgram, CommonCoachState.CoachPending, CommonCoachState.CoachVideoVisitAlreadyScheduledOrComplete, CommonCoachState.Closed];
export enum HealthLogActions {
  RequiredReply,
  DidNotRequireReply,
}
export const HEALTH_LOGS_ACTIONS_LIST = [HealthLogActions.RequiredReply, HealthLogActions.DidNotRequireReply];
export enum CoachCallStates {
  CancelVideoVisit = 'cancel-video-visit',
  CompleteVideoVisit = 'complete-video-visit',
  PerformVideoVisitFollowup = 'perform-video-visit-followup',
  Complete = 'complete',
}