import { HHAlert, HHButton, HHChip, HHDialog, HHDialogActions, HHDialogContent, HHDialogContentText, HHDialogTitle, HHLink, HHList, HHListItem, HHTypography, TextArea, Typography } from '@hinge-health/react-component-library';
import { Close, InfoOutlined, LoopOutlined, UploadFileSharp } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { USER_FEEDBACK } from '../../../constants/strings/user-feedback';
import { useCurrentStoredAdminHook } from '../../../hooks/use-current-stored-admin';
import { currentUserAtom } from '../../../modules/member-360/state/atoms';
import { sendFeedback } from '../../../utils/feedback-dialog-utils';
interface FeedbackDialogContentProps {
  isOpen: boolean;
  toggleFunction: (value: boolean) => void;
}
interface FeedbackData {
  name: string;
  email: string;
}
interface FeedbackMessage {
  feedbackQuestion: string;
  bugTitle: string;
  expectedText: string;
  featureRequestTitle: string;
  expectTxtBugFeature: string;
  memberUuids: string;
}
export const SentComponent = ({
  ...props
}): JSX.Element => {
  if (props.feedbackSubmitted) {
    return <HHDialogContent>
        <HHTypography hhVariant={'section-title'} layoutStyles={{
        marginTop: '10px',
        textAlign: 'center'
      }}>
          {USER_FEEDBACK.SUCCESS_TITLE}
        </HHTypography>
        <HHTypography hhVariant={'muted'} layoutStyles={{
        marginTop: '10px',
        marginBottom: '8px',
        textAlign: 'center'
      }}>
          {USER_FEEDBACK.SUCCESS_ALERT_PREFIX}
          <HHLink href={USER_FEEDBACK.JIRA_BASE_TICKET_URL + props.jiraTicketNumber} target="_blank">
            {props.jiraTicketNumber}
          </HHLink>
          {USER_FEEDBACK.SUCCESS_ALERT_SUFFIX}
        </HHTypography>
        <HHButton hhVariant={'text'} fullWidth layoutStyles={{
        marginTop: '10px',
        marginBottom: '8px',
        textAlign: 'center'
      }} onClick={(): void => props.setFeedbackSubmitted(false)}>
          {USER_FEEDBACK.SUBMIT_ANOTHER_BUTTON_TEXT}
        </HHButton>
      </HHDialogContent>;
  }
  return <HHDialogContent>
      <HHDialogContentText sx={{
      marginBottom: '8px'
    }}>
        {USER_FEEDBACK.DIALOG_TEXTS.TITLE_BUG_REQUEST_QUES}
      </HHDialogContentText>
      <HHChip clickable color={props.bugFeatureChip.bug === props.feedbackMessage.feedbackQuestion ? 'secondary' : 'default'} hhVariant={'filled'} label={props.bugFeatureChip.bug} onClick={(): void => props.handleToggleBugFeatureChip('bug')} />
      <HHChip clickable hhVariant={'filled'} color={props.bugFeatureChip.productFeature === props.feedbackMessage.feedbackQuestion ? 'secondary' : 'default'} layoutStyles={{
      marginLeft: '10px'
    }} label={props.bugFeatureChip.productFeature} onClick={(): void => props.handleToggleBugFeatureChip('productFeature')} />

      <HHDialogContentText sx={{
      marginTop: '15px',
      marginBottom: '8px'
    }}>
        {props.bugFeatureChip.bug === props.feedbackMessage.feedbackQuestion ? USER_FEEDBACK.DIALOG_TEXTS.TITLE_BUG : USER_FEEDBACK.DIALOG_TEXTS.TITLE_FEATURE}
      </HHDialogContentText>
      <TextArea label={''} InputProps={{
      minRows: 1
    }} placeholder={props.bugFeatureChip.bug === props.feedbackMessage.feedbackQuestion ? USER_FEEDBACK.DIALOG_TEXTS.TITLE_BUG_PLACEHOLDER : USER_FEEDBACK.DIALOG_TEXTS.TITLE_FEATURE_PLACEHOLDER} size={'medium'} defaultValue={props.bugFeatureChip.bug === props.feedbackMessage.feedbackQuestion ? props.feedbackMessage.bugTitle : props.feedbackMessage.featureRequestTitle} sx={{
      width: '100%'
    }} onChange={(e): void => props.setFeedbackMessage((prevData: FeedbackMessage) => ({
      ...prevData,
      [props.bugFeatureChip.bug === props.feedbackMessage.feedbackQuestion ? 'bugTitle' : 'featureRequestTitle']: e.target.value
    }))} />
      <HHDialogContentText sx={{
      marginTop: '15px',
      marginBottom: '8px'
    }}>
        {props.bugFeatureChip.bug === props.feedbackMessage.feedbackQuestion ? USER_FEEDBACK.DIALOG_TEXTS.DESCRIPTION : USER_FEEDBACK.DIALOG_TEXTS.TITLE_2_FEATURE}
      </HHDialogContentText>
      <TextArea label={''} placeholder={props.bugFeatureChip.bug === props.feedbackMessage.feedbackQuestion ? USER_FEEDBACK.DIALOG_TEXTS.TITLE_2_BUG_PLACEHOLDER : USER_FEEDBACK.DIALOG_TEXTS.TITLE_2_FEATURE_PLACEHOLDER} size={'medium'} defaultValue={props.bugFeatureChip.bug === props.feedbackMessage.feedbackQuestion ? props.feedbackMessage.expectedText : props.feedbackMessage.expectTxtBugFeature} sx={{
      width: '100%'
    }} onChange={(e_0): void => props.setFeedbackMessage((prevData_0: FeedbackMessage) => ({
      ...prevData_0,
      [props.bugFeatureChip.bug === props.feedbackMessage.feedbackQuestion ? 'expectedText' : 'expectTxtBugFeature']: e_0.target.value
    }))} />
      {props.bugFeatureChip.bug === props.feedbackMessage.feedbackQuestion && <>
          <HHDialogContentText sx={{
        marginTop: '15px',
        marginBottom: '8px'
      }}>
            {USER_FEEDBACK.DIALOG_TEXTS.MEMBER_ID_TITLE}
          </HHDialogContentText>
          <TextArea label={''} placeholder={USER_FEEDBACK.DIALOG_TEXTS.MEMBER_ID_PLACEHOLDER} InputProps={{
        minRows: 1
      }} defaultValue={props.feedbackMessage.memberUuids} sx={{
        width: '100%'
      }} onChange={(e_1): void => props.setFeedbackMessage((prevData_1: FeedbackMessage) => ({
        ...prevData_1,
        memberUuids: e_1.target.value
      }))} />
        </>}
      <HHDialogContentText sx={{
      marginTop: '15px'
    }}>
        {USER_FEEDBACK.DIALOG_TEXTS.ATTACHMENT_TEXT}
      </HHDialogContentText>
      <input data-testid={'file-input'} type={'file'} accept={'image/*, video/*, .har, .doc, .docx, .pdf'} multiple ref={props.fileInputRef} style={{
      display: 'none'
    }} onClick={(e_2): void => e_2.stopPropagation()} onChange={props.handleFeedbackFilesUpload} />
      <HHButton hhVariant={'outlined'} layoutStyles={{
      marginTop: '8px',
      lineHeight: '40px'
    }} onClick={props.handleButtonClick}>
        <UploadFileSharp sx={{
        marginRight: '8px'
      }} />{' '}
        {USER_FEEDBACK.UPLOAD_FILES_TEXT}
      </HHButton>
      {props.feedbackFiles && props.feedbackFiles.length > 0 && <HHList>
          {Array.from((props.feedbackFiles as File[])).map((file: File, index: number) => <HHListItem key={file.name}>
                {file.name}
                <Close data-testid={'delete-file-' + index} sx={{
          marginLeft: '100px'
        }} onClick={(): void => props.handleDeleteItem(index)} />
              </HHListItem>)}
        </HHList>}
      <Box sx={{
      width: '100%',
      bgcolor: 'rgba(207, 185, 145, 0.12)',
      borderRadius: '32px',
      padding: '4px 6px 4px 6px',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '8px'
    }}>
        <Stack direction={'row'} spacing={2}>
          <InfoOutlined sx={{
          color: 'rgba(103, 111, 109, 1)'
        }} />{' '}
          <Typography sx={{
          color: 'rgba(103, 111, 109, 1)',
          marginTop: '2px!important'
        }} align={'center'}>
            {USER_FEEDBACK.DISCLAIMER_TEXT}
          </Typography>
        </Stack>
      </Box>
      <HHDialogActions sx={{
      marginTop: '32px',
      padding: '0px'
    }}>
        {!props.feedbackLoader ? <HHButton hhVariant={'contained'} color={'primary'} onClick={props.handleSubmitFeedback} fullWidth>
            {USER_FEEDBACK.SUBMIT_FORM_BUTTON_TEXT}
          </HHButton> : <HHButton hhVariant={'contained'} color={'primary'} fullWidth>
            <LoopOutlined sx={{
          animation: 'rotate 2s linear infinite'
        }} />
            {'Loading...'}
          </HHButton>}
      </HHDialogActions>
    </HHDialogContent>;
};
export const FeedbackDialog = ({
  toggleFunction,
  isOpen
}: FeedbackDialogContentProps): JSX.Element => {
  const [feedbackFiles, setFeedbackFiles] = useState<FileList | null>(null);
  const [feedbackErrorMessage, setFeedbackErrorMessage] = useState<string>('');
  const [jiraTicketNumber, setJiraTicketNumber] = useState<string>('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false);
  const [feedbackLoader, setFeedbackLoader] = useState<boolean>(false);
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const currentUser = useAtomValue(currentUserAtom);
  const [feedbackData] = useState<FeedbackData>({
    name: `${currentStoredAdmin?.firstName} ${currentStoredAdmin?.lastName}`,
    email: currentStoredAdmin?.email || ''
  });
  const bugFeatureChip = {
    bug: USER_FEEDBACK.BUG_STRING,
    productFeature: USER_FEEDBACK.REQUEST_STRING
  };
  const handleToggleBugFeatureChip = (key: 'bug' | 'productFeature'): void => {
    setFeedbackMessage({
      ...feedbackMessage,
      feedbackQuestion: bugFeatureChip[key]
    });
  };
  const [feedbackMessage, setFeedbackMessage] = useState<FeedbackMessage>({
    feedbackQuestion: bugFeatureChip.bug,
    bugTitle: '',
    expectedText: '',
    featureRequestTitle: '',
    expectTxtBugFeature: '',
    memberUuids: currentUser?.uuid || ''
  });
  const [initFeedbackMessage] = useState<FeedbackMessage>(feedbackMessage);

  // Function to trigger file input click
  const handleButtonClick = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Programmatically click the file input
    }
  };
  const handleDeleteItem = (index: number): void => {
    if (feedbackFiles) {
      const files = Array.from(((feedbackFiles as unknown) as File[]));
      files.splice(index, 1);
      const fileList = ((files as unknown) as FileList);
      setFeedbackFiles(fileList);
    }
  };
  const handleSubmitFeedback = async (): Promise<void> => {
    // Concat the description
    const prefixMsg = `
    ${USER_FEEDBACK.FORMATTED_DESC_TEMPLATES.FIRST_LINE}
    ${USER_FEEDBACK.FORMATTED_DESC_TEMPLATES.FIRST_LINE_ANS} ${bugFeatureChip.bug === feedbackMessage.feedbackQuestion ? feedbackMessage.feedbackQuestion : USER_FEEDBACK.REQUEST_STRING_JIRA}`;
    const messageBody1 = bugFeatureChip.bug === feedbackMessage.feedbackQuestion ? `${USER_FEEDBACK.DIALOG_TEXTS.TITLE_2_BUG_PLACEHOLDER}\n${feedbackMessage.expectedText}` : `${USER_FEEDBACK.DIALOG_TEXTS.TITLE_2_FEATURE}\n${feedbackMessage.expectTxtBugFeature}`;
    const messageBody2 = bugFeatureChip.bug === feedbackMessage.feedbackQuestion && feedbackMessage.memberUuids.length ? USER_FEEDBACK.FORMATTED_DESC_TEMPLATES.MEMBER_ID_PREFIX + feedbackMessage.memberUuids : '';
    const message = `${prefixMsg} \n${messageBody1} \n${messageBody2}`;
    setFeedbackLoader(true);
    const sendFeedbackCall = (await sendFeedback({
      userData: feedbackData,
      message,
      type: feedbackMessage.feedbackQuestion,
      feedbackFiles,
      title: bugFeatureChip.bug === feedbackMessage.feedbackQuestion ? feedbackMessage.bugTitle : feedbackMessage.featureRequestTitle
    }) as {
      data: {
        key: string;
      };
      status: boolean;
    });
    setFeedbackErrorMessage('');
    setFeedbackSubmitted(true);
    setFeedbackLoader(false);
    localStorage.removeItem(USER_FEEDBACK.FEEDBACK_PERSIST_KEY);
    // set the initial/blank feedback messages after submission
    setFeedbackMessage(initFeedbackMessage);
    setJiraTicketNumber(sendFeedbackCall?.data?.key);
    setFeedbackFiles(null);
  };
  useEffect(() => {
    const storedValue = localStorage.getItem(USER_FEEDBACK.FEEDBACK_PERSIST_KEY);
    if (storedValue) {
      setFeedbackMessage(JSON.parse(storedValue));
    }
  }, []);
  const handleCloseFeedbackDialog = (): void => {
    toggleFunction(!isOpen);
    // Save the unsaved feedback in local storage
    if (!feedbackSubmitted) {
      localStorage.setItem(USER_FEEDBACK.FEEDBACK_PERSIST_KEY, JSON.stringify(feedbackMessage));
    }
  };

  //   function to handle the file upload
  const handleFeedbackFilesUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files) {
      setFeedbackFiles(e.target.files);
    }
  };
  return <HHDialog open={isOpen}
  // closeAfterTransition
  sx={{
    padding: '5px'
  }} PaperProps={{
    sx: {
      width: '500px',
      borderRadius: '15px',
      position: 'absolute',
      bottom: 0,
      left: '40px',
      padding: '10px'
    }
  }}>
      {feedbackErrorMessage && <HHAlert hhVariant={'standard'} layoutStyles={{
      marginTop: '10px'
    }} severity={'error'}>
          {feedbackErrorMessage}
        </HHAlert>}
      <HHDialogTitle>
        {!feedbackSubmitted && USER_FEEDBACK.DIALOG_TEXTS.FROM_TITLE}
        <Close data-testid={'close-button'} sx={{
        float: 'right',
        cursor: 'pointer'
      }} onClick={(): void => handleCloseFeedbackDialog()} />{' '}
      </HHDialogTitle>
      <SentComponent feedbackSubmitted={feedbackSubmitted} setFeedbackSubmitted={setFeedbackSubmitted} bugFeatureChip={bugFeatureChip} feedbackMessage={feedbackMessage} handleToggleBugFeatureChip={handleToggleBugFeatureChip} fileInputRef={fileInputRef} handleFeedbackFilesUpload={handleFeedbackFilesUpload} handleButtonClick={handleButtonClick} handleSubmitFeedback={handleSubmitFeedback} setFeedbackMessage={setFeedbackMessage} feedbackLoader={feedbackLoader} jiraTicketNumber={jiraTicketNumber} feedbackFiles={feedbackFiles} handleDeleteItem={handleDeleteItem} />
    </HHDialog>;
};