import { HHButton, HHLoadingButton, HHStack } from '@hinge-health/react-component-library';
import { useHHCreateLayoutTabContext } from '@hinge-health/react-component-library/dist/components/molecules/hh-layout-panel';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { createCareCoordinationWorkflowAtom } from '../../state/atoms';
import { FORM_ACTION_BUTTONS } from '../constants/create-workflow-forms';
import { CancelConfirmationDialog } from './cancel-confirmation-dialog';
export const WORKFLOW_FORM_ACTIONS_TEST_ID = 'workflow-form-actions';
export interface WorkflowFormActionsProps {
  loading: boolean;
  disabled?: boolean;
  onSubmit: () => void;
  onCancel?: () => void;
}
export const WorkflowFormActions = ({
  loading,
  onSubmit,
  onCancel,
  disabled
}: WorkflowFormActionsProps): JSX.Element => {
  const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] = useState(false);
  const {
    onClose
  } = useHHCreateLayoutTabContext();
  const [createCareCoordinationWorkflow] = useAtom(createCareCoordinationWorkflowAtom);
  useEffect(() => {
    if (createCareCoordinationWorkflow.success) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCareCoordinationWorkflow]);
  const handleSubmit = (): void => {
    onSubmit();
  };
  return <HHStack data-testid={WORKFLOW_FORM_ACTIONS_TEST_ID} flexDirection={'row'} justifyContent={'flex-end'} columnGap={4}>
      <HHButton onClick={(): void => setShowCancelConfirmationDialog(true)} hhVariant="outlined" disabled={loading}>
        {FORM_ACTION_BUTTONS.CANCEL}
      </HHButton>
      <HHLoadingButton loading={loading} onClick={handleSubmit} hhVariant="contained" disabled={loading || disabled}>
        {FORM_ACTION_BUTTONS.CREATE}
      </HHLoadingButton>
      <CancelConfirmationDialog open={showCancelConfirmationDialog} onCancel={(): void => setShowCancelConfirmationDialog(false)} onContinue={onCancel} />
    </HHStack>;
};