export const THREE_PANEL_LAYOUT = 'three-panel-layout';
export const CONFIGURATOR_2 = 'configurator-2';
export const WOMENS_PELVIC_HEALTH = 'womens-pelvic-health';
export const MEMBER_INFO_SECTION = 'member-info';
export const CARE_TEAM_SECTION = 'care-team-section';
export const MEMBER_RECORD_CUT_OVER_SPLIT = 'member-record-cut-over';
export const MEMBER_RECORD_CUT_OVER_SPLIT_M3 = 'member-record-cut-over-m3';
export const MEMBER_INDICATION_TAB = 'member-indication-tab';
export const ON = 'on';
export const NOTES_TAB = 'member-notes-tab';
export const MEMBER_TAGS_TREATMENT = 'member-tags';
export const CAREHUB_PREFERRED_NAME_SPLIT = 'carehub-preferred-name';
export const MEMBER_STRIKETHROUGH = 'carehub-member-strikethrough';