import { HHBox } from '@hinge-health/react-component-library';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PhiFieldsMaskingKey, PhiFieldsMaskingStatusChangedEvent } from '../types/types';
import { mixpanelTrack } from '../utils/mixpanel';
interface PageContainerProps {
  children: React.ReactNode;
}
export const PAGE_CONTAINER_TEST_ID = 'page-container';
const PageContainer = ({
  children
}: PageContainerProps): JSX.Element => {
  const location = useLocation();
  const [phiFieldsMasked, setPhiFieldsMasked] = React.useState(localStorage.getItem(PhiFieldsMaskingKey));
  const trackPageView = (): void => {
    const pagePath = location.pathname.split('/')[1] || '';
    const eventName = `Page Location: ${pagePath}`;
    mixpanelTrack?.(eventName, {
      Page: window.location.toString()
    });
  };
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Utilize the PHI masking status change event to update the component
  useEffect(() => {
    const phiMaskingStatusChanged = (): void => {
      setPhiFieldsMasked(localStorage.getItem(PhiFieldsMaskingKey));
    };
    window.addEventListener(PhiFieldsMaskingStatusChangedEvent, phiMaskingStatusChanged);
    return (): void => {
      window.removeEventListener(PhiFieldsMaskingStatusChangedEvent, phiMaskingStatusChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <HHBox key={phiFieldsMasked?.toString()} data-testid={PAGE_CONTAINER_TEST_ID} sx={{
    width: '100%',
    height: '100%'
  }}>
      {children}
    </HHBox>;
};
export default PageContainer;