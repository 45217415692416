export const FORM_LABELS = {
  CANCEL: 'Cancel',
  SAVE: 'Save',
  DELETE: 'Delete',
  PAUSE_CHECKBOX_TEXT: 'Pause proactive workflows until alert expires',
  REMINDER_DATE: 'Reminder Date',
  END_DATE: 'End Date',
  START_DATE: 'Start Date',
  DESCRIPTION: 'Description',
  CATEGORY: 'Category',
  ADD_AN_ALERT_TEXT: 'Add an alert',
  EDIT_AN_ALERT_TEXT: 'Edit alert',
  REMINDER_CHECKBOX_TEXT: 'Set a reminder for yourself:',
  REQUIRED_DATE_FIELD_ERROR: 'Please input a valid date',
  REQUIRED_FIELD_ERROR: 'This is a required field',
  INVALID_START_DATE_ERROR: 'Must be today or later',
  INVALID_END_DATE_ERROR: 'Must equal start date or later',
  INVALID_REMINDER_DATE_ERROR: 'Must be after today',
  DATE_ERROR: 'Please check date inputs',
  MAX_LENGTH_250_ERROR: 'Must be less than 250 characters',
  MAX_LENGTH_250_REACHED: 'Character limit reached (max 250)',
  COMPLETE_ALL_FIELDS_ERROR: 'Please complete all required fields before saving'
};
export const FILTER_LABELS = {
  STATUS: 'Status',
  AUTHOR: 'Author',
  CATEGORY: 'Category',
  STATUS_FILTER: 'statusFilter',
  AUTHOR_FILTER: 'authorFilter',
  CATEGORY_FILTER: 'categoryFilter'
};
export const STATUS_FILTERS = {
  ACTIVE: 'Active',
  UPCOMING: 'Upcoming',
  EXPIRED: 'Expired'
};
export const BANNER_LABELS = {
  ALL: 'All',
  ACTIVE_AND_UPCOMING: 'Active & Upcoming',
  MEMBER_ALERTS: 'Member Alerts',
  ADD: 'Add'
};
export const MEMBER_INFO_LABELS = {
  MEMBER_INFO: 'Member Info',
  LEGAL_NAME: 'Legal name',
  PREFERRED_NAME: 'Preferred name',
  NAME: 'Name',
  USER_ID: 'User ID',
  MEMBER_ID: 'Member ID',
  SECURE_ID: 'Secure ID',
  COPY_MEMBER_ID: 'Copy Member ID',
  COPIED_MEMBER_ID: 'Copied Member ID',
  MEMBER_ID_UNAVAILABLE: 'Member ID unavailable',
  COPY_SECURE_ID: 'Copy Secure ID',
  COPIED_SECURE_ID: 'Copied Secure ID',
  SECURE_ID_UNAVAILABLE: 'Secure ID unavailable',
  TECHNICAL_USES: 'For technical uses only',
  COPY_URL: 'copy',
  COPIED_URL: 'copied',
  DOB: 'DOB',
  GENDER: 'Gender',
  HEIGHT: 'Height',
  WEIGHT: 'Weight',
  BMI: 'BMI',
  OCCUPATION: 'Occupation',
  EMPLOYER: 'Employer',
  ONBOARDER_DATE: 'Onboarded date',
  MEMBER: 'Member',
  BACKGROUND: 'Background',
  STATE_REGULATIONS: 'State regulations',
  MEMBER_BIO: 'Member bio',
  ACTION: 'Action',
  STATE_OF_RESIDENCE: 'State of residence',
  REGULATION: 'Regulation',
  DA_ALLOWED_FOR_NO_INJURY: 'DA allowed for no injury',
  HCP_DETAILS_REQD_IF_NOT_REFERRED: 'HCP details required if not referred',
  INFORM_HCP_AFTER_FIRST_VISIT_DAYS: 'Inform physician 5 days after video visit',
  REFER_ONLY_NO_PROGRESS: 'Refer only no progress',
  RX_REQD_FOR_30_DAY_PLUS_RETURN: 'Obtain Rx if patient is returning for similar condition after 30 days',
  RX_REQD_FOR_DIAGNOSED_CHRONIC: 'Obtain Rx before video visit if no prior diagnosis in the past 90 days',
  SIGNED_NOTICE_COVERAGE_LIMITS: 'Signed notice coverge limits',
  SIGNED_NOTICE_PT_LIMITS: 'Signed notice pt limits',
  TIME_LIMIT_DAYS: 'Time limit days',
  VISIT_LIMIT: 'Visit limit',
  EXERCISE_RESTRICTION: 'Exercise restrictions',
  UPCOMING_SURGERIES: 'Upcoming surgeries or procedures',
  CHANCE_OF_FUTURE_SURGERY: 'Chance for future surgery',
  PAIN_DESCRIPTION: 'Pain description',
  SURGERIES_OR_PROCEDURE: 'Surgeries or procedures',
  SURGERIES_OR_DATE: 'Surgery or procedure date',
  KEY_REASON: 'Key reasons',
  IMAGINE_PAIN_FREE: 'Imagine pain free',
  FORSEE_ANY_BARRIERS: 'Forsee any barriers',
  NO_DATA: '-',
  ACTION_REQUIRED: 'Action required',
  NA: 'NA',
  COMMUNICATION_PREFERENCES: 'Communication preferences',
  UPCOMING_SURGERIES_FIELD_NOT_SET: 'None'
};
export const FORM_TEST_IDS = {
  CATEGORY_SELECT: 'member-alert-category-select',
  DESCRIPTION_TEXTFIELD: 'member-alert-description-textfield',
  START_DATE_PICKER: 'member-alert-start-date-picker',
  END_DATE_PICKER: 'member-alert-end-date-picker',
  REMINDER_DATE_PICKER: 'member-alert-reminder-date-picker',
  DELETE_BUTTON: 'member-alert-delete-button',
  CANCEL_BUTTON: 'member-alert-cancel-button',
  SAVE_BUTTON: 'member-alert-save-button',
  REMINDER_CHECKBOX: 'member-alert-reminder-checkbox',
  ERROR_BANNER: 'member-alert-error-banner',
  STATUS_FILTER: 'member-alert-status-filter',
  AUTHOR_FILTER: 'member-alert-author-filter',
  CATEGORY_FILTER: 'member-alert-category-filter',
  FILTER_ICON: 'member-alert-filter-icon',
  MEMBER_ALERT_FILTERS: 'member-alert-filters',
  MEMBER_ALERT_BANNER_OPTIONS: 'member-alert-banner-options',
  FILTER_CLOSE_ICON: 'member-alert-filter-close-icon',
  DELETE_MODAL: 'delete-modal'
};
export const DELETE_MODAL_LABELS = {
  TITLE: 'Are you sure you want to delete?',
  BODY: "Deleting a member alert will permanently remove it from the member's record.",
  // eslint-disable-line
  CANCEL: 'Cancel',
  DELETE: 'Delete'
};
export const DATE_ERROR_TYPES = {
  REQUIRED_DATE: 'required-date',
  INVALID_START_DATE: 'invalid-start-date',
  INVALID_END_DATE: 'invalid-end-date',
  INVALID_REMINDER_DATE: 'invalid-reminder-date'
};
export const ACCOUNT_DETAILS_TESTID = {
  EMPTY_TOGGLE_BUTTON: 'account-details-empty-field-toggle',
  MEMBER_CONTAINER: 'account-details-member-container',
  BACKGROUND_CONTAINER: 'account-details-background-container',
  STATE_REGULATIONS_CONTAINER: 'state-regulations-container',
  STATE_REGULATIONS_DETAILS: 'state-regulations-details',
  UPCOMING_SURGERY: 'account-details-upcoming-surgery',
  UPCOMING_SURGERY_UNIFIED_USER: 'account-details-upcoming-surgery-unified-user',
  FUTURE_SURGERY: 'account-details-future-surgery',
  EXERCISE_RESTRICTION: 'account-details-exercise-restriction',
  PERISURGICAL_DETAIL: 'account-details-perisurgical',
  PAIN_DESCRIPTION: 'account-details-pain-description',
  ACCOUNT_DETAILS: 'account-details'
};
export const CARE_TEAM_OWNER_TYPE = {
  PHYSICAL_THERAPIST: 'PHYSICAL_THERAPIST',
  HEALTH_COACH: 'HEALTH_COACH',
  ONBOARDING_SPECIALIST: 'ONBOARDING_SPECIALIST'
};
export const PREFERRED_NAME_UPDATED = 'Preferred name updated';
export const MEMBER_ALERT_DELETE_ERROR = 'Error while deleting member alert';
export const MEMBER_ALERT_UPDATE_ERROR = 'Error while updating member alert';
export const MEMBER_ALERT_CREATE_ERROR = 'Error while creating member alert';
export const MEMBER_ALERT_MAX_DESCRIPTION_LENGTH_250 = 250;