import dayjs from 'dayjs';
import { AcuteExpiringTab, AcuteExpiringTasksCategory, AcuteGroupType, AcuteWorkflowTypes, AllAcuteWorkflowTypes, CarePlanGroup, ClosingActions, ExpiringCarePlanActions, HouseCallsGroupType, HouseCallsWorkflows, VideoVisitsTab, VideoVisitsTasksCategory, VideoVisitStatus, VideoVisitsWithoutMedicalHistory, VideoVisitsWorkflows } from '../../custom-types';
import { DATE_FORMAT } from '../dates';
import { FULL_MONTH_DAY_YEAR } from './dayjs-date-formats';
export const ACUTE_STRINGS = {
  BACK: 'Back',
  DATE: 'Date',
  DUE: 'Due',
  MEMBER: 'Member',
  MESSAGE: 'Message',
  TYPE: 'Type',
  EVENT_TIME: 'Event time',
  UNKNOWN_MEMBER: 'Unknown member',
  JOIN_BUTTON: 'Join',
  MARK_COMPLETE_BUTTON: 'Mark complete',
  COMPLETE: 'Complete',
  STATUS: 'Status',
  PLAN_EXPIRES: 'Plan expires',
  TASKS_COULD_NOT_BE_FOUND: 'Tasks could not be found',
  REFRESH_PAGE: 'Try refreshing the page',
  STATUS_NOT_FOUND: 'Status not found',
  NO_DATA: '--',
  MEMBER_ALERT_REMIND_AT: 'Reminder task:',
  UNREAD_MESSAGES: 'unread message',
  NOT_SCHEDULED: 'Not scheduled',
  UNABLE_LOAD_LOAD_FOLLOWUP: 'Unable to load follow-up date'
};
export const CHIP_STRINGS = {
  TOTAL: 'Total',
  OVERDUE: 'Overdue',
  DUE_SOON: 'Due soon'
};
export const VIDEO_VISITS_TABS_STRINGS: Record<VideoVisitsTab, string> = {
  [VideoVisitsTab.Prepare]: 'Prepare',
  [VideoVisitsTab.Perform]: 'Perform',
  [VideoVisitsTab.Post]: 'Post'
};
export const ACUTE_EXPIRING_TABS_STRINGS: Record<AcuteExpiringTab, string> = {
  [AcuteExpiringTab.Tasks]: 'Tasks',
  [AcuteExpiringTab.Resources]: 'Resources'
};
export const ACUTE_RESOLVE_FORM = {
  RESOLVE_ACCORDION_HEADING: 'Resolve',
  NEXT_TASKS_HEADING: 'Suggested workflows',
  SEE_ALL_TEXT: 'See All',
  MARK_COMPLETE_TEXT: 'Mark Complete'
};
export const ACUTE_CARE_PLAN = {
  DA_LIMIT_APPROACHING: 'DA limit approaching',
  DC_AND_CLOSE_PLAN: 'Discharge and close plan',
  PENDING_EXTENSION: 'Pending extension',
  EXPIRING_SOON: 'Expiring soon',
  CARE_PLANS_TO_CLOSE: 'Care plans to close',
  EXPIRING_CARE_PLANS: 'Expiring care plans'
};
export const ACUTE_TABS: Record<AcuteWorkflowTypes, string> = {
  [AcuteWorkflowTypes.UnreadMessages]: 'Messages',
  [AcuteWorkflowTypes.VideoVisitsWorkflowGroup]: 'Encounters',
  [AcuteWorkflowTypes.HealthLog]: 'HLN',
  [AcuteWorkflowTypes.PainUptick]: 'Pain',
  [AcuteWorkflowTypes.CarePlanGroup]: 'Care Plans'
};
export const PT_MEMBER_VIEW_HEADER_TITLE: Record<AllAcuteWorkflowTypes, string> = {
  [AcuteWorkflowTypes.UnreadMessages]: 'Unread Message',
  [AcuteWorkflowTypes.VideoVisitsWorkflowGroup]: 'Video Visit',
  [AcuteWorkflowTypes.HealthLog]: 'Health Log Note',
  [AcuteWorkflowTypes.PainUptick]: 'Pain Uptick',
  [AcuteWorkflowTypes.CarePlanGroup]: 'Care Plans',
  [CarePlanGroup.Closing]: ACUTE_CARE_PLAN.CARE_PLANS_TO_CLOSE,
  [CarePlanGroup.Expiring]: ACUTE_CARE_PLAN.EXPIRING_CARE_PLANS,
  [VideoVisitsWorkflows.Acute]: 'Video Visit',
  [VideoVisitsWorkflows.Chronic]: 'Video Visit',
  [VideoVisitsWorkflows.Surgery]: 'Video Visit',
  [HouseCallsWorkflows.HouseCall]: 'House Call',
  [VideoVisitsWithoutMedicalHistory.FifteenMinute]: 'Video Visit'
};
export const ACUTE_HEADER = {
  HEADER: {
    TITLE: 'Your tasks',
    subTitleCompleted: (completedCount: number, totalCount: number): string => `${completedCount} of ${totalCount} completed`,
    subTitleDue: (overDueCount: number, dueSoonCount: number): string => `${overDueCount} overdue, ${dueSoonCount} due soon`
  }
};
export const ACUTE_SIDEBAR_HEADER = {
  HEADER: {
    titleCompleted: (completedCount: number, totalCount: number): string => `${completedCount} of ${totalCount} tasks completed`
  }
};
export const ACUTE_CARD_TITLES: Record<AcuteWorkflowTypes, Record<string, string>> = {
  [AcuteWorkflowTypes.UnreadMessages]: {
    [AcuteGroupType.Title]: 'Unread messages'
  },
  [AcuteWorkflowTypes.VideoVisitsWorkflowGroup]: {
    [AcuteGroupType.Incomplete]: 'Incomplete Video Visits',
    [AcuteGroupType.Upcoming]: 'Upcoming Video Visits',
    [HouseCallsGroupType.UpcomingHouseCall]: 'Upcoming House Calls',
    [HouseCallsGroupType.OpenHouseCall]: 'Open House Calls'
  },
  [AcuteWorkflowTypes.HealthLog]: {
    [AcuteGroupType.Title]: 'Health log notes'
  },
  [AcuteWorkflowTypes.PainUptick]: {
    [AcuteGroupType.Title]: 'Pain uptick'
  },
  [AcuteWorkflowTypes.CarePlanGroup]: {
    [CarePlanGroup.Expiring]: ACUTE_CARE_PLAN.EXPIRING_CARE_PLANS,
    [CarePlanGroup.Closing]: ACUTE_CARE_PLAN.CARE_PLANS_TO_CLOSE
  }
};
export const dashBoardTitle = (adminFirstName: string): string => `PT ${adminFirstName}'s Dashboard`;
export const calendarTitle = (): string => dayjs().format(FULL_MONTH_DAY_YEAR);
export const TIME_STRINGS = {
  IN: 'in',
  TOMORROW: 'Tomorrow',
  DAYS: 'days'
};
export const VIDEO_VISIT_MAP: Record<string, string> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '0/6': VideoVisitStatus.Evaluation,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '1/6': VideoVisitStatus.FirstFollowup,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '2/6': VideoVisitStatus.SecondFollowup,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '3/6': VideoVisitStatus.ThirdFollowup,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '4/6': VideoVisitStatus.FourthFollowup,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '5/6': VideoVisitStatus.FifthFollowup,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '6/6': VideoVisitStatus.SixthFollowup
};
export const VIDEO_VISIT_TYPES: string[] = [VideoVisitStatus.Evaluation, VideoVisitStatus.FirstFollowup, VideoVisitStatus.SecondFollowup, VideoVisitStatus.ThirdFollowup, VideoVisitStatus.FourthFollowup, VideoVisitStatus.FifthFollowup, VideoVisitStatus.SixthFollowup];
export const VIDEO_VISIT_15_MIN_TYPE = '15 min video visit';
export const MEMBER_DETAILS_TABLE = {
  MEMBER: 'Member',
  MEMBER_ID: 'Member ID',
  EMAIL: 'Email',
  CPT_CODE: 'CPT code',
  ASSIGNEE: 'Assignee',
  TIMEZONE: 'Time zone',
  FOLLOWUP_VISIT: 'F/up visit',
  PLAN_LIMIT: 'Plan limit',
  PLAN_EXPIRES: 'Plan expires',
  VERIFY: 'Verify'
};
export const PLAN_LIMIT_TITLE = 'd - Direct Access';
export const VIDEO_VISITS_TASKS_NAME = {
  SEND_POST_EVAL: 'Send post-eval',
  SEND_POST_EVAL_IN_APP_MESSAGE: 'Send post-eval in app message',
  SEND_POST_FOLLOWUP: 'Send post follow-up',
  CREATE_WEEK1: 'Create week 1',
  OPT: '(optional)',
  COMPLETE_6_WK: 'Complete 6wk',
  COMPLETE: 'Complete',
  START: 'Start',
  UPLOAD_NOTE: 'Upload note to',
  UPDATE: 'Update',
  COMPLETE_FOLLOWUP_NOTE: 'Complete follow-up note',
  LOG_PT_VISIT_IN: 'Log PT visit in',
  CREATE: 'Create',
  COMPLETE_DISCHARGE_NOTE: 'Complete discharge note'
};
export const ACUTE_EXPIRING_TASKS_NAME = {
  INQUIRE_PLAN_EXTENSION: 'Inquire about plan extension by ',
  DOWNLOAD_EVALUATION: 'Download evaluation from ',
  FAX_EVALUATION: 'Fax evaluation in ',
  CONFIRM_PROVIDER_INFORMED: 'Confirm provider informed in ',
  CONFIRM_POC_RX_RECEIVED: 'Confirm POC/Rx recieved in ',
  UPLOAD_SIGNED_POC_RX: 'Upload signed POC/Rx to '
};
export const VIDEO_VISIT_BODY_SECTION = {
  REASON_FOR_VISIT: 'Reason for visit',
  REVIEW_MEMBER_INFORMATION: 'Review member information',
  START_NOTE: ' Start note',
  PREPARE_POST_VISIT_TASKS: 'Prepare post-visit tasks',
  NO_SHOW_OR_CANCEL: 'If no show or cancellation',
  MEMBER_INFORMATION: 'Member information',
  VIDEO_VISIT: 'Video visit',
  NO_VIDEO_VISITS_TASK: 'No tasks available',
  RESOURCES: 'Resources',
  VIDEO_VISIT_LAUNCH: 'Launch Video Visit'
};
export const ACUTE_EXPIRING_RESOURCES_BODY_SECTION = {
  INVESTIGATE: 'Investigate'
};
export const VIDEO_VISIT_SIDE_BAR_CATEGORIES: Record<VideoVisitsTasksCategory, string> = {
  [VideoVisitsTasksCategory.Asap]: 'As soon as possible',
  [VideoVisitsTasksCategory.WithinDay]: 'Within a day'
};
export const ACUTE_EXPIRING_CATEGORIES: Record<AcuteExpiringTasksCategory, string> = {
  [AcuteExpiringTasksCategory.ConnectWithMember]: 'Connect with member',
  [AcuteExpiringTasksCategory.ConnectWithProvider]: 'Connect with provider',
  [AcuteExpiringTasksCategory.ConnectCarePlanExtension]: 'Connect care plan extension'
};
export const ACUTE_LINKS = {
  CTT_PROFILE: 'CTT profile',
  CTT_PROFILE_OVERVIEW: 'CTT profile - Overview Page',
  CTT_PROFILE_ENGAGEMENT: 'CTT profile - Engagement Data',
  CTT_EVAL_NOTE: 'CTT Eval note',
  UQ_EVAL_TEMPLATE: 'UQ eval template',
  LQ_EVAL_TEMPLATE: 'LQ eval template',
  ADMIN_PROFILE: 'Admin profile',
  APPLICATION_QUESTIONNAIRE: 'Application Q',
  MEDICAL_HISTORY: 'Medical history',
  EVALUATION_TEMPLATE: 'Eval template',
  EVALUATION_NOTE: 'Eval note',
  GDOC_EVAL_TEMPLATE: 'Gdoc eval template',
  GDOC_FIFTEEN_MINUTES_EVAL_TEMPLATE: 'Gdoc 15 min VV template',
  GDOC_EVAL_TEMPLATE_PRE_OP: 'Gdoc eval template Pre Op',
  GDOC_EVAL_TEMPLATE_POST_OP: 'Gdoc eval template Post Op',
  FOLLOW_UP_TEMPLATE: 'Follow-up template',
  FOLLOW_UP_NOTE: 'Follow-up note',
  HELPSCOUT: 'Helpscout',
  EMAIL: 'Email',
  EMAIL_LOWERCASE: 'email',
  CANCEL: 'Cancel',
  RESCHEDULE: 'Reschedule',
  BOOKING: 'Booking',
  CALENDAR: 'Calendar',
  JOIN_ROOM: 'Join room',
  PHONE: 'Phone',
  OPTIONAL: '(optional)',
  RESPOND: 'Respond',
  PAIN_HISTORY: 'Pain history',
  EXERCISE_PLAN: 'Exercise plan',
  GURU_ARTICLES: 'Guru articles',
  ET_PDFS: 'ET PDFs',
  TIMEZONE: 'Time zone',
  REQUEST_EQUIPMENT: 'Request equipment',
  CARE_PLAN: 'care plan',
  PHI_DRIVE: 'PHI drive',
  OVERVIEW: 'Overview',
  RING_CENTRAL: 'Ring Central',
  CONCORD: 'Concord Fax',
  CARE_PLAN_LINK: 'Care plan',
  EMAIL_SMALL: 'email',
  CTT: 'CTT',
  IN_CTT: 'in CTT',
  PHI_FOLDER: 'PHI folder',
  DISCHARGE_NOTE: 'discharge note',
  BOOKING_MANAGEMENT: 'Booking Management',
  IN_APP_MESSAGING: 'In App Messaging',
  MEMBER_RECORD: 'Member Record',
  SOAP_NOTE: 'SOAP note',
  TRAVEL_REIMBURSEMENT: 'travel reimbursement',
  ZOOM: 'Zoom'
};
export const EVALUATION_TEMPLATE_MAP: Record<string, string> = {
  SHOULDER: 'https://docs.google.com/document/d/1n0O69TJSSnonjeYv60wLj_HJ_urrHaDfp9T9jQ98Yz8/edit#',
  NECK: 'https://docs.google.com/document/d/1wXx85dNxlcbDKdZzijU9tQNhL1kvbHhEZtiqizOd928/edit#',
  BACK: 'https://docs.google.com/document/d/1xV5IHeyggSQW-MstWSCmDJLyXJWRcELXNRilI-QkWSw/edit#',
  KNEE: 'https://docs.google.com/document/d/1UFo0zGyeN5ly1roayUjzPvcjP-bN_ozfefVd4mfs2Lo/edit#',
  HIP: 'https://docs.google.com/document/d/1WUtGoOQjOxMkhbzVDeEG4lzT80wI8XkmMaxlZFaTDmE/edit#',
  WRIST: 'https://docs.google.com/document/d/1gXEgoYf8_EjlUuWKUnVf1NaSKg9ji-TuClsphlKXuOg/edit#',
  HAND: 'https://docs.google.com/document/d/1gXEgoYf8_EjlUuWKUnVf1NaSKg9ji-TuClsphlKXuOg/edit#',
  ELBOW: 'https://docs.google.com/document/d/1gXEgoYf8_EjlUuWKUnVf1NaSKg9ji-TuClsphlKXuOg/edit#',
  FINGER: 'https://docs.google.com/document/d/1gXEgoYf8_EjlUuWKUnVf1NaSKg9ji-TuClsphlKXuOg/edit#',
  ANKLE: 'https://docs.google.com/document/d/1IyThNZo6MLnWAk6409HeFrXVqBEgyWjttcUqqF1FKrY/edit#',
  FOOT: 'https://docs.google.com/document/d/1IyThNZo6MLnWAk6409HeFrXVqBEgyWjttcUqqF1FKrY/edit#'
};
export const CHRONIC_EVALUATION_TEMPLATE_MAP: Record<string, string> = {
  NECK: 'https://docs.google.com/document/d/1yIVhK0avi-okt1_8uIkbLGp724xcxqLjOwXNDiQpXEQ/edit#',
  SHOULDER: 'https://docs.google.com/document/d/1yIVhK0avi-okt1_8uIkbLGp724xcxqLjOwXNDiQpXEQ/edit#',
  BACK: 'https://docs.google.com/document/d/1t9Vv5yEr3ujcwf1Lmy-opwr77aHrbMgdn2P9gsC6wLs/edit#',
  HIP: 'https://docs.google.com/document/d/1t9Vv5yEr3ujcwf1Lmy-opwr77aHrbMgdn2P9gsC6wLs/edit#',
  KNEE: 'https://docs.google.com/document/d/1t9Vv5yEr3ujcwf1Lmy-opwr77aHrbMgdn2P9gsC6wLs/edit#',
  ELBOW: 'https://docs.google.com/document/d/1yqoU7Hy1TrCZBlXz2De_YFi_DkDmJGifBkg6BTzWBow/edit#',
  WRIST: 'https://docs.google.com/document/d/1yqoU7Hy1TrCZBlXz2De_YFi_DkDmJGifBkg6BTzWBow/edit#',
  HAND: 'https://docs.google.com/document/d/1yqoU7Hy1TrCZBlXz2De_YFi_DkDmJGifBkg6BTzWBow/edit#',
  FOOT: 'https://docs.google.com/document/d/17El-QSETCUuM195nb2U_8q6AZPPxIkJeYNkw-BC19lU/edit#',
  ANKLE: 'https://docs.google.com/document/d/17El-QSETCUuM195nb2U_8q6AZPPxIkJeYNkw-BC19lU/edit#',
  BALANCE: 'https://docs.google.com/document/d/1GueUOGosN0Rw7mHFAwypX4BMAS3amyfrO7cd1W-T2X4/edit#'
};
export const SURGERY_EVALUATION_TEMPLATE_MAP: Record<string, string> = {
  KNEE_PRE_OP: 'https://docs.google.com/document/d/1xy8f8T2IcAKarEGEvniJAHgi8pNfu3uwq2wIqMZZoCk/edit#heading=h.1ll0cbjpprj',
  KNEE_POST_OP: 'https://docs.google.com/document/d/17xoGN00arFFhI2Z0N8nKlmX6YRrg4T3JVF0FRN0Lcjo/edit#',
  HIP_PRE_OP: 'https://docs.google.com/document/d/1xy8f8T2IcAKarEGEvniJAHgi8pNfu3uwq2wIqMZZoCk/edit#heading=h.1ll0cbjpprj',
  HIP_POST_OP: 'https://docs.google.com/document/d/17xoGN00arFFhI2Z0N8nKlmX6YRrg4T3JVF0FRN0Lcjo/edit#',
  NECK_PRE_OP: 'https://docs.google.com/document/d/1kpYS-SYLbQnpjB4atJk_Lvrpnm3EIxE-VTr_7WZ3teg/edit#heading=h.1ll0cbjpprj',
  NECK_POST_OP: 'https://docs.google.com/document/d/1xsP5KYlh72swFSphgum7nobiPbj9taZ7U6hP1WIG238/edit#',
  BACK_PRE_OP: 'https://docs.google.com/document/d/1kpYS-SYLbQnpjB4atJk_Lvrpnm3EIxE-VTr_7WZ3teg/edit#heading=h.1ll0cbjpprj',
  BACK_POST_OP: 'https://docs.google.com/document/d/1xsP5KYlh72swFSphgum7nobiPbj9taZ7U6hP1WIG238/edit#'
};
export const painUptickHeader = (dateString: string): string => `Reported on ${dayjs(dateString).format(DATE_FORMAT)}`;
export const healthLogNoteHeader = (dateString: string): string => `Sent ${dayjs(dateString).format(DATE_FORMAT)}`;
export const CLOSING_ACTIONS_TEXT: Record<ClosingActions, string> = {
  [ClosingActions.EmailSentToMember]: 'Email sent to member',
  [ClosingActions.DismissNoReply]: 'Dismiss, no reply'
};
export const CLOSING_ACTIONS_VALUE: Record<ClosingActions, string> = {
  [ClosingActions.EmailSentToMember]: 'email-sent-to-member',
  [ClosingActions.DismissNoReply]: 'dismiss-no-reply'
};
export const EXPIRING_CARE_PLAN_ACTIONS_TEXT: Record<ExpiringCarePlanActions, string> = {
  [ExpiringCarePlanActions.CarePlanExtended]: 'Care plan extended',
  [ExpiringCarePlanActions.MarkToClosePlan]: 'Mark to close plan'
};
export const EXPIRING_CARE_PLAN_ACTIONS_VALUE: Record<ClosingActions, string> = {
  [ClosingActions.EmailSentToMember]: 'care-plan-extended',
  [ClosingActions.DismissNoReply]: 'mark-to-close-plan'
};
export const CLOSING_ACTIONS_HEADER = 'Closing actions';
export const ACUTE_DETAIL_STRINGS = {
  INVESTIGATE: 'Investigate'
};
export const ACUTE_COMPLETE_TASKS = {
  taskCount: (completedCount: number, totalCount: number): string => `${completedCount} of ${totalCount} tasks complete!`,
  SEE_ALL_TASKS: 'See all your tasks',
  NEXT_TASKS: 'Next task suggestions'
};
export const LOAD_MORE = 'Load more';
export const GOOGLE_ACCESS = {
  LOGGED_OUT_SUBHEADER: 'It looks like you are logged out of Google',
  SIGN_IN_CTA_INFO: 'Please sign in below to see calendar events',
  SIGN_IN_WITH_GOOGLE: 'Sign in with Google'
};
export const GOOGLE_ACCESS_SIDEBAR = {
  SIGN_INTO_GOOGLE: 'Sign into Google',
  SIGN_IN: 'SIGN IN',
  SIGN_IN_INFO: 'Some of these links require a Google log in. Sign in to access the links.'
};
export const ACUTE_CARE_PLAN_TEST_ID = {
  PENDING_EXTENSION_CHIP: 'pending-extension-chip',
  EXPIRING_SOON_CHIP: 'expiring-soon-chip'
};
export const CARE_PLAN_TO_CLOSE_TASK_NAME = {
  DISCHARGE_AND_CLOSE_PLAN: 'Discharge and close plan in',
  COMPLETE_DISCHARGE_NOTE: 'Complete the discharge note in'
};
export const CARE_PLAN_TO_CLOSE_TASK_SECTION = {
  CLOSE_PLAN: 'Close plan'
};
export const ERRORS = {
  LOADING_ERROR: 'Loading error',
  WORKFLOW_LOADING_ERROR_SUB_TEXT: 'There was an error getting the workflow data. Please try again.',
  NETWORK_ERROR: 'Network error',
  TASK_UPDATE_ERROR_SUB_TEXT: 'Task could not be completed. Please try again.'
};
export const VERIFY_MEMBER_POPOVER = {
  ADDRESS: 'Address',
  PHONE_NUMBER: 'Phone number',
  DATE_OF_BIRTH: 'Date of birth'
};
export const NO_VISIT_REASON_PROVIDED = 'Reason for scheduling was not added. Connect with member to learn more.';
export const VIDEO_VISIT_JOIN_ROOM = 'VideoVisitJoinRoom';