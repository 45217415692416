import _ from 'lodash';
import { CURRENT_MEMBER_ID, SESSION_ID, SESSON_START_TIME, TIME_SPENT_ON_MEMBER } from '../../../../constants/strings/member';

// Generate a unique session ID
export function generateSessionId(): string {
  const randomStr = _.random(0, Math.pow(36, 11) - 1).toString(36);
  return 'sess-' + randomStr.slice(0, 11);
}

// Start a new session
export function startSession(): void {
  const sessionId = generateSessionId();
  const startTime = new Date().getTime();
  localStorage.setItem(SESSION_ID, sessionId);
  localStorage.setItem(SESSON_START_TIME, startTime.toString());
}

// End the current session
export function endSession(): void {
  const sessionId = localStorage.getItem(SESSION_ID);
  const startTime = localStorage.getItem(SESSON_START_TIME);
  if (sessionId && startTime) {
    const endTime = new Date().getTime();
    const timeSpent = (endTime - parseInt(startTime, 10)) / 1000;
    localStorage.setItem(TIME_SPENT_ON_MEMBER, timeSpent.toString());
    localStorage.removeItem(SESSION_ID);
    localStorage.removeItem(SESSON_START_TIME);
    localStorage.removeItem(CURRENT_MEMBER_ID);
  }
}