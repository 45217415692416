import { useCallback, useEffect, useState } from 'react';
export interface LastSearchedPt {
  adminUuid: string;
  lastSearchedPTs: Array<string>;
}
export const LAST_SEARCHED_PTS_KEY = 'lastSearchedPTs';
export const useLastSearchedPt = (adminUuid: string): [LastSearchedPt | null, (ptAdminUuid: string) => void] => {
  const [existingValue, setExistingValue] = useState(() => {
    const lastSeachedPtsStringified = localStorage.getItem(LAST_SEARCHED_PTS_KEY);
    return lastSeachedPtsStringified ? (JSON.parse(lastSeachedPtsStringified) as LastSearchedPt) : null;
  });
  const appendUuidToPtList = (currentPtList: string[], newPtUuid: string): string[] => {
    const currentPtListCopy = [...currentPtList];
    const length = currentPtListCopy.length;
    const indexOfExistingPt = currentPtListCopy.indexOf(newPtUuid);
    const isExistingPt = indexOfExistingPt > -1;
    if (indexOfExistingPt > -1) {
      // remove the existing pt from the list
      currentPtListCopy.splice(indexOfExistingPt, 1);
    }
    if (length >= 5 && !isExistingPt) {
      currentPtListCopy.pop();
    }
    currentPtListCopy.unshift(newPtUuid);
    return currentPtListCopy;
  };
  const setLastSeachedPTs = useCallback((ptAdminUuid: string): void => {
    const currentPtList_0 = {
      adminUuid: existingValue?.adminUuid || adminUuid,
      lastSearchedPTs: existingValue?.lastSearchedPTs || []
    };
    const newPtList = appendUuidToPtList(currentPtList_0.lastSearchedPTs, ptAdminUuid);
    const newLastSearchedPTs = {
      ...currentPtList_0,
      lastSearchedPTs: newPtList
    };
    localStorage.setItem(LAST_SEARCHED_PTS_KEY, JSON.stringify(newLastSearchedPTs));
    setExistingValue(newLastSearchedPTs);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [existingValue]);
  useEffect(() => {
    if (existingValue === null || existingValue.adminUuid !== adminUuid) {
      const deaultValue = {
        adminUuid,
        lastSearchedPTs: []
      };
      localStorage.setItem(LAST_SEARCHED_PTS_KEY, JSON.stringify(deaultValue));
      setExistingValue(deaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingValue]);
  return [existingValue, setLastSeachedPTs];
};