import { HHBox, HHCircularProgress, useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { SignInOptions } from '@hinge-health/react-component-library/dist/components/auth/hinge-health-security-context';
import { useClient } from '@splitsoftware/splitio-react';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NAV_SPLIT_TREATMENTS, ON } from '../constants/strings/split';
import { useShellContext } from '../modules/sidebar/contexts/shell.context';
import { SPLIT_TREATMENTS } from '../pages/os';
import { OktaRole } from '../types/okta-roles';
import RootScreen from './root-screen';
interface AuthenticatedPageProps {
  children: React.ReactNode;
}
const styles = {
  containerStyle: {
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  spinnerStyle: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
};
const AuthenticatedPage = ({
  children
}: AuthenticatedPageProps): JSX.Element => {
  const {
    hingeHealthAuthState,
    hingeHealthAuthClient
  } = useHingeHealthSecurityContext();
  const {
    adminProfile
  } = useShellContext();
  const splitClient = useClient();
  const location = useLocation();
  const navigate = useNavigate();
  const ptDashboard = splitClient?.getTreatment(NAV_SPLIT_TREATMENTS.PT_DASHBOARD, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  const osOnboardingTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.OS_ONBOARDING, {
    adminUuid: adminProfile.uuid || '*'
  }) === ON;
  useEffect(() => {
    if (!hingeHealthAuthState) {
      return;
    }
    if (!hingeHealthAuthState?.isAuthenticated) {
      const signInOptions = ({
        originalUri: `${location.pathname}?redirect=${process.env.REACT_APP_BASE_NAME}`
      } as SignInOptions);
      hingeHealthAuthClient.signInWithRedirect(signInOptions);
    } else {
      const userRoles = hingeHealthAuthState?.accessToken?.claims?.roles;
      const isPT = userRoles?.includes(OktaRole.PhysicalTherapist);
      const isCoach = userRoles?.includes(OktaRole.Coach);
      const isOS = userRoles?.includes(OktaRole.OnboardingSpecialist);
      const hasRedirectedToPT = localStorage.getItem('hasRedirectedToPT') === 'true';
      const hasRedirectedToCoach = localStorage.getItem('hasRedirectedToCoach') === 'true';
      const hasRedirectedToOS = localStorage.getItem('hasRedirectedToOS') === 'true';
      if (ptDashboard && isPT && !hasRedirectedToPT) {
        // Set the flag in local storage
        localStorage.setItem('hasRedirectedToPT', 'true');
        navigate('/pt', {
          replace: true
        });
      }
      if (isCoach && !hasRedirectedToCoach) {
        // Set the flag in local storage
        localStorage.setItem('hasRedirectedToCoach', 'true');
        navigate('/coach', {
          replace: true
        });
      }
      if (isOS && !hasRedirectedToOS) {
        // Set the flag in local storage
        localStorage.setItem('hasRedirectedToOS', 'true');
        osOnboardingTreatment ? navigate('/os', {
          replace: true
        }) : navigate('/member-search', {
          replace: true
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hingeHealthAuthState, hingeHealthAuthClient, ptDashboard, navigate]);
  return <HHBox sx={styles.containerStyle}>
      {!hingeHealthAuthState?.isAuthenticated ? <HHCircularProgress size={24} sx={styles.spinnerStyle} /> : <RootScreen>{children}</RootScreen>}
    </HHBox>;
};
export default AuthenticatedPage;