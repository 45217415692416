import { atom } from 'jotai';
import { atomWithReset, splitAtom } from 'jotai/utils';
import { coachQueueWorkflowCategories, CoachWorkflowTypes, osQueueWorkflowCategories, OsWorkflowTypes, ptQueueWorkflowCategories, PtWorkflowTypes } from '../custom-types';
import { ApplicationPathway, Maybe } from '../types';
import { OktaRole } from '../types/okta-roles';
export type FetchMoreWorkflowsAtom = {
  queueCategory: CoachWorkflowTypes[] | PtWorkflowTypes[] | OsWorkflowTypes[];
  fetchPageNumber: number;
  categoryTotal: number;
};
export type UpcomingEncounter = {
  patientDetails: string;
  callDate: string;
  type: CoachWorkflowTypes | PtWorkflowTypes;
  workflowId: number;
  patientId?: string;
  followups: number;
  applicationPathway?: Maybe<ApplicationPathway>;
};
export const listOfCoachEncounterCategories = atom<FetchMoreWorkflowsAtom[]>([{
  queueCategory: [CoachWorkflowTypes.CoachCall],
  fetchPageNumber: 1,
  categoryTotal: 0
}]);
export const listOfPtEncounterCategories = atom<FetchMoreWorkflowsAtom[]>([{
  queueCategory: [PtWorkflowTypes.Chronic, PtWorkflowTypes.Acute, PtWorkflowTypes.Surgery, PtWorkflowTypes.FifteenMinute],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [PtWorkflowTypes.HouseCall],
  fetchPageNumber: 1,
  categoryTotal: 0
}]);
export const listOfCoachWorkflowCategories = atom<FetchMoreWorkflowsAtom[]>([{
  queueCategory: [CoachWorkflowTypes.UnreadMessages],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [CoachWorkflowTypes.MemberPainUptick],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [CoachWorkflowTypes.MemberHealthLog],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [CoachWorkflowTypes.ReEngager],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [CoachWorkflowTypes.FirstWeekGoal],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [CoachWorkflowTypes.ActionPlanReview],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [CoachWorkflowTypes.ActionPlanCheckIn],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [CoachWorkflowTypes.FirstWeekCheckIn],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [CoachWorkflowTypes.MemberAlertReminder],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [CoachWorkflowTypes.EnsoWeek1],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [CoachWorkflowTypes.CoachEscalation],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [CoachWorkflowTypes.WeeklyEnsoReview],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [CoachWorkflowTypes.OutreachNeeded],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [CoachWorkflowTypes.MaternityMilestones],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [CoachWorkflowTypes.PregnancyStatusChange],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [CoachWorkflowTypes.BalanceAssessment],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [CoachWorkflowTypes.CoachWeeklyGoalExpiry],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal],
  fetchPageNumber: 1,
  categoryTotal: 0
}]);
export const listOfOsWorkflowCategories = atom<FetchMoreWorkflowsAtom[]>([{
  queueCategory: [OsWorkflowTypes.UnreadMessages],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [OsWorkflowTypes.MemberAlertReminder],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [OsWorkflowTypes.ReEngager],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [OsWorkflowTypes.OutreachNeeded],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [OsWorkflowTypes.Nuto],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [OsWorkflowTypes.Ruto],
  fetchPageNumber: 1,
  categoryTotal: 0
}]);

/* 
1. The order of the below elements should match the order of the elements in ptQueueWorkflowCategories in src/custom-types.ts 
2. The order of the below elements is used to determine order the queue categories in the Queue panel.
3. Since PT_CATEGORY_NAME_ARRAY_INDEX is an array, 
the labels are accessed based on index values generated using PT_CATEGORY_NAME_ARRAY_INDEX. 
Ex: Line 234 on src/components/workflows-queue-components/queue-accordion.tsx   
4. The order of the elements in PT_CATEGORY_NAME_ARRAY_INDEX and ptQueueWorkflowCategories below 
are used to determine tooltip text on Next button.
*/
export const listOfPtWorkflowCategories = atom<FetchMoreWorkflowsAtom[]>([{
  queueCategory: [PtWorkflowTypes.Acute, PtWorkflowTypes.Surgery, PtWorkflowTypes.FifteenMinute, PtWorkflowTypes.Chronic],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [PtWorkflowTypes.UnreadMessages, PtWorkflowTypes.ModularIamUnreadMessages],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [PtWorkflowTypes.HealthLog],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [PtWorkflowTypes.PainUptick, PtWorkflowTypes.MemberPainUptick],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [PtWorkflowTypes.SurgeryTrend],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [PtWorkflowTypes.ClinicalEscalation],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [PtWorkflowTypes.EtModification],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [PtWorkflowTypes.IndicationUpdate],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [PtWorkflowTypes.MedicalEmergencyFollowUp],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [PtWorkflowTypes.SetupActionPlan],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [PtWorkflowTypes.ActionPlanReview],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [PtWorkflowTypes.Expiring],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [PtWorkflowTypes.Closing],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [PtWorkflowTypes.AqRedFlags],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [PtWorkflowTypes.FirstWeekEnsoCheckIn],
  fetchPageNumber: 1,
  categoryTotal: 0
}, {
  queueCategory: [PtWorkflowTypes.PtMemberAlertReminder],
  fetchPageNumber: 1,
  categoryTotal: 0
}]);
const listOfWorkflowCategories = atom(get => {
  const roles = get(rolesAtom);
  if (roles?.includes(OktaRole.Coach)) {
    return get(listOfCoachWorkflowCategories);
  } else if (roles?.includes(OktaRole.PhysicalTherapist)) {
    return get(listOfPtWorkflowCategories);
  } else if (roles?.includes(OktaRole.OnboardingSpecialist)) {
    return get(listOfOsWorkflowCategories);
  }
  return [];
}, (get, set, update: React.SetStateAction<FetchMoreWorkflowsAtom[]>) => {
  const roles = get(rolesAtom);
  if (roles?.includes(OktaRole.Coach)) {
    return set(listOfCoachWorkflowCategories, update);
  } else if (roles?.includes(OktaRole.PhysicalTherapist)) {
    return set(listOfPtWorkflowCategories, update);
  } else if (roles?.includes(OktaRole.OnboardingSpecialist)) {
    return set(listOfOsWorkflowCategories, update);
  }
  return [];
});
const listOfEncounterCategories = atom(get => {
  const roles = get(rolesAtom);
  if (roles?.includes(OktaRole.Coach)) {
    return get(listOfCoachEncounterCategories);
  } else if (roles?.includes(OktaRole.PhysicalTherapist)) {
    return get(listOfPtEncounterCategories);
  }
  return [];
}, (get, set, update: React.SetStateAction<FetchMoreWorkflowsAtom[]>) => {
  const roles = get(rolesAtom);
  if (roles?.includes(OktaRole.Coach)) {
    return set(listOfCoachEncounterCategories, update);
  } else if (roles?.includes(OktaRole.PhysicalTherapist)) {
    return set(listOfPtEncounterCategories, update);
  }
  return [];
});
export const workflowCategoryAtoms = splitAtom(listOfWorkflowCategories);
export const encounterCategoryAtoms = splitAtom(listOfEncounterCategories);
export interface QueueWorkflowIdentifiers {
  id: number;
  patientId?: string;
  type: string;
  nextId: number;
}
export enum Sentinels {
  End = -1,
  ShowMore = -2,
}
export const queueDisplay = atom<boolean>(false);
export const currentQueueCategoryIndexAtom = atom<number>(0);
export const currentQueueWorkflowIdAtom = atom<number>(0);
export const tempCurrentQueueCategoryIndexAtom = atomWithReset<number | undefined>(undefined);
export const tempQueueWorkflowIdAtom = atomWithReset<number | undefined>(undefined);
export const queueComplete = atom<boolean>(false);
export const currentAdminUuid = atom<string>('');
export const fetchMoreCurrentWorkflowIdAtom = atom<number>(0);
export const lastWorkflowInCategoryAtom = atomWithReset<boolean>(false);
const coachInitialLists = new Array(coachQueueWorkflowCategories.length).fill(new Map());
export const coachWorkflowIdMapsByQueueCategoryAtom = atom<Map<number, QueueWorkflowIdentifiers>[]>(coachInitialLists);
export const coachWorkflowIdMapsByQueueCategorySplitAtom = splitAtom(coachWorkflowIdMapsByQueueCategoryAtom);
export const osInitialLists = new Array(osQueueWorkflowCategories.length).fill(new Map());
export const osWorkflowIdMapsByQueueCategoryAtom = atom<Map<number, QueueWorkflowIdentifiers>[]>(osInitialLists);
export const osWorkflowIdMapsByQueueCategorySplitAtom = splitAtom(osWorkflowIdMapsByQueueCategoryAtom);
const ptInitialLists = new Array(ptQueueWorkflowCategories.length).fill(new Map());
export const ptWorkflowIdMapsByQueueCategoryAtom = atom<Map<number, QueueWorkflowIdentifiers>[]>(ptInitialLists);
export const ptWorkflowIdMapsByQueueCategorySplitAtom = splitAtom(ptWorkflowIdMapsByQueueCategoryAtom);
export const workflowCountsByQueueCategoryAtom = atom<{
  count: number;
  categoryIndex: number;
}[]>([]);
export const fetchFirstPageCurrentCategoryAtom = atom<{
  categoryIndex: number;
  fetchFirstPage: boolean;
}>({
  categoryIndex: 0,
  fetchFirstPage: false
});
export const upcomingEncountersAtom = atom<UpcomingEncounter[] | undefined>(undefined);
export const currentQueueTabIndexAtom = atom<number>(0);
export const rolesAtom = atom<[string] | null>(null);